import React, { useState } from "react";
import Staff from "../types/Staff";
type StaffContextType = {
  statusState: string;
  setStatusState: (status: string) => void;
  responseData: Staff[];
  setResponseData: (staff: Staff[]) => void;
  loading: boolean;
  setLoading: (status: boolean) => void;
  setSkipPageReset: (status: boolean) => void;
  skipPageReset: boolean;
  responseStatus: {
    responseStatusChanged: boolean;
    responseIsOk: boolean;
  };
  setResponseStatus: (status: {
    responseStatusChanged: boolean;
    responseIsOk: boolean;
  }) => void;
  inputIsChanged: boolean;
  setinputIsChanged: (status: boolean) => void;
  setSearchInput: (input: string) => void;
  searchInput: string;
  setFilterInput: (input: string) => void;
  filterInput: string;
};

export const StaffContext = React.createContext<StaffContextType>({
  statusState: "",
  setStatusState: () => {
    //
  },
  responseData: [],
  setResponseData: () => {
    //
  },
  loading: false,
  setLoading: () => {
    //
  },
  setSkipPageReset: () => {
    //
  },
  skipPageReset: false,
  responseStatus: { responseStatusChanged: false, responseIsOk: false },
  setResponseStatus: () => {
    //
  },
  inputIsChanged: false,
  setinputIsChanged: () => {
    //
  },
  setSearchInput: () => {
    //
  },
  searchInput: "",
  setFilterInput: () => {
    //
  },
  filterInput: "",
});

const StaffContextProvider: React.FC = ({ children }) => {
  const [statusState, setStatusState] = useState("AL");
  const [responseData, setResponseData] = useState<Array<Staff>>([]);
  const [loading, setLoading] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [inputIsChanged, setinputIsChanged] = useState(false);
  const [searchInput, setSearchInput] = useState("search=");
  const [filterInput, setFilterInput] = useState("status=AL");
  const [responseStatus, setResponseStatus] = useState({
    responseStatusChanged: false,
    responseIsOk: false,
  });
  
  return (
    <StaffContext.Provider
      value={{
        statusState,
        setStatusState,
        responseData,
        setResponseData,
        loading,
        setLoading,
        setSkipPageReset,
        skipPageReset,
        responseStatus,
        setResponseStatus,
        inputIsChanged,
        setinputIsChanged,
        setSearchInput,
        searchInput,
        setFilterInput,
        filterInput,
      }}
    >
      {children}
    </StaffContext.Provider>
  );
};

export default StaffContextProvider;
