import React, { useState } from "react";

type SelectedSubscribersContextType = {
  selectedSubscribers: string[];
  selectSubscriber: (slug: string) => void;
  deselectSubscriber: (slug: string) => void;
  selectAll: (slug: string[]) => void;
  deselectAll: (slug: string[]) => void;
  clearSelectedSubscribers: () => void;
};

export const SelectedSubscribersContext =
  React.createContext<SelectedSubscribersContextType>({
    selectedSubscribers: [],
    selectSubscriber: () => {
      //
    },
    deselectSubscriber: () => {
      //
    },
    selectAll: () => {
      //
    },
    deselectAll: () => {
      //
    },
    clearSelectedSubscribers: () => {
      //
    },
  });

const SelectedSubscribersContextProvider: React.FC = ({ children }) => {
  const [selectedSubscribers, setSelectedSubscribers] = useState<string[]>([]);

  const selectSubscriber = (slug: string) => {
    setSelectedSubscribers((preSelectedSubscribers) => {
      if (preSelectedSubscribers.indexOf(slug) === -1) {
        return [...preSelectedSubscribers, slug];
      } else {
        return preSelectedSubscribers;
      }
    });
  };

  const deselectSubscriber = (slug: string) => {
    setSelectedSubscribers((preSelectedSubscribers) =>
      preSelectedSubscribers.filter(
        (preSelectedSubscriber) => preSelectedSubscriber !== slug
      )
    );
  };

  const selectAll = (slugs: string[]) => {
    setSelectedSubscribers((preSelectedSubscribers) => [
      ...preSelectedSubscribers,
      ...slugs.filter((slug) => preSelectedSubscribers.indexOf(slug) === -1),
    ]);
  };

  const deselectAll = (slugs: string[]) => {
    setSelectedSubscribers((preSelectedSubscribers) =>
      preSelectedSubscribers.filter(
        (preSelectedSubscriber) => slugs.indexOf(preSelectedSubscriber) === -1
      )
    );
  };

  const clearSelectedSubscribers = () => {
    setSelectedSubscribers([]);
  };

  return (
    <SelectedSubscribersContext.Provider
      value={{
        selectedSubscribers,
        selectSubscriber,
        deselectSubscriber,
        selectAll,
        deselectAll,
        clearSelectedSubscribers,
      }}
    >
      {children}
    </SelectedSubscribersContext.Provider>
  );
};

export default SelectedSubscribersContextProvider;
