import React from "react";
import { useTranslation } from "react-i18next";

const FromToInputContainer: React.FC<{
  className?: string;
  titleKey: string;
}> = ({ className, titleKey, children }) => {
  const { t } = useTranslation();

  return (
    <div className={`${className}`}>
      <label className="text-sm text-black41 mb-1" >{t(titleKey)}</label>
      <div className="flex">{children}</div>
    </div>
  );
};

export default FromToInputContainer;
