import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/pro-regular-svg-icons";

const InformationSection: React.FC<{
  className?: string;
  title: string;
  information: {
    label: string;
    value: string;
  }[];
}> = ({ className, title, information }) => {
  const [showInfo, setShowInfo] = useState(true);

  const parentContainer = useRef<HTMLDivElement>(null);
  const childContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showInfo) {
      parentContainer.current!.style.height = "auto";
    } else {
      parentContainer.current!.style.height = "0px";
    }
  }, [showInfo]);

  return (
    <section className={`bg-white rounded ${className}`}>
      <div
        className={`py-4 px-6 flex justify-between items-center rounded-t bg-gray-612d duration-700 ${
          !showInfo && "rounded-b"
        }`}
      >
        <p
          className={`font-semibold ${
            showInfo ? "text-black41" : "text-blue-secondary"
          }`}
        >
          {title}
        </p>
        <FontAwesomeIcon
          icon={faAngleUp}
          className={`text-sm text-gray-8d cursor-pointer duration-300 ${
            showInfo ? "rotate-0" : "-rotate-180"
          }`}
          onClick={() => setShowInfo((preShowInfo) => !preShowInfo)}
        />
      </div>
      <div ref={parentContainer} className="duration-300 overflow-hidden">
        <div
          className="grid 888:grid-cols-3 640:grid-cols-2 grid-cols-1 gap-10 p-6"
          ref={childContainer}
        >
          {information.map((info, index) => (
            <article key={index} className="text-sm text-black41">
              <p className="font-semibold">{info.label}</p>
              <p>{info.value}</p>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InformationSection;
