import React from "react";

import CameroonLogo from "../../images/logo-v-cameroon.svg";
import LebanonLogo from "../../images/logo-v-lebanon.svg";
import RwandaLogo from "../../images/logo-v-rwanda.svg";
import background from "../../images/background.png";

const AuthContainer: React.FC = (props) => {
    const selectLogo = () => {
      if (process.env.REACT_APP_Country === "Cameroon") {
        return CameroonLogo;
      } else if (process.env.REACT_APP_Country === "Lebanon") {
        return LebanonLogo;
      } else if (process.env.REACT_APP_Country === "Rwanda") {
        return RwandaLogo;
       }
    };

    return (
        <main className="page">
            <img className="w-auto h-full absolute right-0 bottom-0" src={background} alt="" />
            <section className="w-95/100 max-w-[490px] flex flex-col bg-white rounded p-8 z-10">
                <img className="w-40 mx-auto" src={selectLogo()} alt="CIMA" />
                {props.children}
            </section>
        </main>
    );
};

export default AuthContainer;
