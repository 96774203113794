import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { SearchFilterDefaultersContext } from "../../../store/SearchFilterDefaultersContext";
import { getFormattedDate } from "../../../utils/DateUtils";
import Dropdown, { DropdownType } from "../../UI/Dropdown";
import FromToInputContainer from "../../UI/FromToInputContainer";
import DatePicker from "../../UI/DatePicker";
import FilterActions from "../../UI/FilterActions";
import FilterTag from "../../UI/FilterTag";
import i18next from "i18next";
import useRole from "../../../hooks/useRole";

const DefaulterFilterSection: React.FC<{
    onApply: () => void;
}> = ({ onApply }) => {
    const { t } = useTranslation();
    const { isClinicAdmin } = useRole();

    const {
        defaulterFilter,
        setDefaulterFilter,
        defaulterAppliedFilter,
        setDefaulterAppliedFilter,
        clinicTitles,
        reset,
        isAnyFilterApplied,
    } = useContext(SearchFilterDefaultersContext);

    let lastReminderSentFilterTagValue = "";
    if (defaulterAppliedFilter.lastReminderSentFrom && defaulterAppliedFilter.lastReminderSentTo) {
        lastReminderSentFilterTagValue = `${getFormattedDate(defaulterAppliedFilter.lastReminderSentFrom)} - ${getFormattedDate(
            defaulterAppliedFilter.lastReminderSentTo
        )}`;
    } else if (defaulterAppliedFilter.lastReminderSentFrom && !defaulterAppliedFilter.lastReminderSentTo) {
        lastReminderSentFilterTagValue = `${t("from")} ${getFormattedDate(defaulterAppliedFilter.lastReminderSentFrom)}`;
    } else if (!defaulterAppliedFilter.lastReminderSentFrom && defaulterAppliedFilter.lastReminderSentTo) {
        lastReminderSentFilterTagValue = `${t("to")} ${getFormattedDate(defaulterAppliedFilter.lastReminderSentTo)}`;
    }

    let missedVaccinesScheduledDateFilterTagValue = "";
    if (defaulterAppliedFilter.missedVaccinesScheduledDateFrom && defaulterAppliedFilter.missedVaccinesScheduledDateTo) {
        missedVaccinesScheduledDateFilterTagValue = `${getFormattedDate(
            defaulterAppliedFilter.missedVaccinesScheduledDateFrom
        )} - ${getFormattedDate(defaulterAppliedFilter.missedVaccinesScheduledDateTo)}`;
    } else if (defaulterAppliedFilter.missedVaccinesScheduledDateFrom && !defaulterAppliedFilter.missedVaccinesScheduledDateTo) {
        missedVaccinesScheduledDateFilterTagValue = `${t("from")} ${getFormattedDate(
            defaulterAppliedFilter.missedVaccinesScheduledDateFrom
        )}`;
    } else if (!defaulterAppliedFilter.missedVaccinesScheduledDateFrom && defaulterAppliedFilter.missedVaccinesScheduledDateTo) {
        missedVaccinesScheduledDateFilterTagValue = `${t("to")} ${getFormattedDate(defaulterAppliedFilter.missedVaccinesScheduledDateTo)}`;
    }

    return (
        <div>
            <section className="w-95/100 mx-auto xl:flex justify-between items-center pt-1 pb-6">
                <div className="flex flex-col 1020:flex-row">
                    {!isClinicAdmin() && clinicTitles && (
                        <div>
                            <label className="text-sm text-black41">{t("clinic")}</label>
                            <Dropdown
                                type={DropdownType.PRIMARY}
                                extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
                                onOptionSelected={(option) => {
                                    setDefaulterFilter((preDefaulterFilter) => {
                                        return { ...preDefaulterFilter, clinicTitleSlug: option };
                                    });
                                }}
                                keys={["", ...clinicTitles.map((clinicTitle) => clinicTitle.slug)]}
                                selectedKey={defaulterFilter.clinicTitleSlug}
                                getValueByKey={(key) => {
                                    if (key === "") return t("all");

                                    return clinicTitles.find((clinicTitle) => clinicTitle.slug === key)!.name;
                                }}
                            />
                        </div>
                    )}
                    <div className="flex flex-col mt-3 1020:mt-0 640:flex-row-reverse justify-end 1020:justify-between items-baseline">
                        <FromToInputContainer titleKey="missed_vaccines" className="1020:mx-0 640:mx-6"
                        >
                            <DatePicker
                                inputClassName="w-[128px] placeholder:text-gray-d7"
                                containerClassName="w-[128px]"
                                selected={defaulterFilter.missedVaccinesScheduledDateFrom}
                                placeholderText={t("from")}
                                onChange={(value) =>
                                    setDefaulterFilter((preDefaulterFiler) => {
                                        return {
                                            ...preDefaulterFiler,
                                            missedVaccinesScheduledDateFrom: value,
                                        };
                                    })
                                }
                                maxDate={defaulterFilter.missedVaccinesScheduledDateTo}
                            />
                            <DatePicker
                                inputClassName="w-[128px] mx-3 placeholder:text-gray-d7"
                                containerClassName="w-[128px]"
                                selected={defaulterFilter.missedVaccinesScheduledDateTo}
                                placeholderText={t("to")}
                                onChange={(date) =>
                                    setDefaulterFilter((preDefaulterFiler) => {
                                        return {
                                            ...preDefaulterFiler,
                                            missedVaccinesScheduledDateTo: date,
                                        };
                                    })
                                }
                                minDate={defaulterFilter.missedVaccinesScheduledDateFrom}
                            />
                        </FromToInputContainer>
                        <FromToInputContainer titleKey="last_reminder_sent" className={`960:mx-0 mt-3 640:mt-0 ${isClinicAdmin() ? (i18next.language === "ar" ? "1020:ml-8" : "1020:mr-8") : "1020:mx-6"}`}
                        >
                            <DatePicker
                                inputClassName="w-[128px] placeholder:text-gray-d7"
                                containerClassName="w-[128px]"
                                selected={defaulterFilter.lastReminderSentFrom}
                                placeholderText={t("from")}
                                onChange={(date) =>
                                    setDefaulterFilter((preDefaulterFiler) => {
                                        return {
                                            ...preDefaulterFiler,
                                            lastReminderSentFrom: date,
                                        };
                                    })
                                }
                                maxDate={defaulterFilter.lastReminderSentTo}
                            />
                            <DatePicker
                                inputClassName="w-[128px] mx-3 placeholder:text-gray-d7"
                                containerClassName="w-[128px]"
                                selected={defaulterFilter.lastReminderSentTo}
                                placeholderText={t("to")}
                                onChange={(date) =>
                                    setDefaulterFilter((preDefaulterFiler) => {
                                        return { ...preDefaulterFiler, lastReminderSentTo: date };
                                    })
                                }
                                minDate={defaulterFilter.lastReminderSentFrom}
                            />
                        </FromToInputContainer>
                    </div>
                    <div className={`${i18next.language === "ar" ? "1020:mr-7" : "1020:ml-7"} 640:mx-0 1020:mt-9 mt-3`}>
                        <label className="text-[14px]">
                            <input
                                className="mx-1.5"
                                type="checkbox"
                                checked={defaulterFilter.isMandatory}
                                onChange={() =>
                                    setDefaulterFilter((preDefaulterFilter) => ({
                                        ...preDefaulterFilter,
                                        isMandatory: !preDefaulterFilter.isMandatory,
                                    }))
                                }
                            />
                            {t("show_mandatory_vaccines")}
                        </label>
                    </div>
                </div>
                <FilterActions isAnyFilterApplied={isAnyFilterApplied} reset={reset} onApply={onApply} />
            </section>
            {isAnyFilterApplied() && (
                <section className="w-95/100 mx-auto py-4 flex flex-wrap items-center">
                    <p className="capitalize mt-1 text-sm text-black41">{t("applied_filter")}:</p>
                    {defaulterAppliedFilter.clinicTitleSlug !== "" && (
                        <FilterTag
                            label={t("clinic")}
                            value={clinicTitles.find((clinicTitle) => clinicTitle.slug === defaulterAppliedFilter.clinicTitleSlug)!.name}
                            onClose={() => {
                                setDefaulterAppliedFilter((preDefaulterAppliedFilter) => {
                                    return {
                                        ...preDefaulterAppliedFilter,
                                        clinicTitleSlug: "",
                                    };
                                });
                            }}
                        />
                    )}
                    {missedVaccinesScheduledDateFilterTagValue && (
                        <FilterTag
                            label={t("missed_vaccines")}
                            value={missedVaccinesScheduledDateFilterTagValue}
                            onClose={() => {
                                setDefaulterAppliedFilter((preDefaulterAppliedFilter) => {
                                    return {
                                        ...preDefaulterAppliedFilter,
                                        missedVaccinesScheduledDateFrom: null,
                                        missedVaccinesScheduledDateTo: null,
                                    };
                                });
                            }}
                        />
                    )}
                    {lastReminderSentFilterTagValue && (
                        <FilterTag
                            label={t("last_reminder_sent")}
                            value={lastReminderSentFilterTagValue}
                            onClose={() => {
                                setDefaulterAppliedFilter((preDefaulterAppliedFilter) => {
                                    return {
                                        ...preDefaulterAppliedFilter,
                                        lastReminderSentFrom: null,
                                        lastReminderSentTo: null,
                                    };
                                });
                            }}
                        />
                    )}
                    {defaulterAppliedFilter.isMandatory === true && (
                        <FilterTag
                            label={t("show_mandatory_vaccines")}
                            value={t("yes")}
                            onClose={() => {
                                setDefaulterAppliedFilter((preDefaulterAppliedFilter) => {
                                    return {
                                        ...preDefaulterAppliedFilter,
                                        isMandatory: false,
                                    };
                                });
                            }}
                        />
                    )}
                </section>
            )}
        </div>
    );
};

export default DefaulterFilterSection;
