import React, { useContext, useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import Pagination from "../../../UI/Pagination";
import SubscribersRow from "./SubscribersRow";
import Subscriber from "../../../../types/Subscriber";
import { SelectedSubscribersContext } from "../../../../store/SelectedSubscribersContext";
import TableHeader from "../../../UI/TableHeader";

interface Column {
  header: string;
  accessor: any;
}

//////////////////////////////////////////////
const SubscribersTable: React.FC<{
  columns: Column[];
  data: Subscriber[];
  editHandler: (status: string) => void;
  updateStatusHandler: (_id: string) => void;
}> = ({ columns, data, editHandler, updateStatusHandler }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
    },

    useSortBy,
    usePagination
  );

  const { selectedSubscribers, selectAll, deselectAll } = useContext(SelectedSubscribersContext);

  useEffect(() => {
    page.length === 0 && previousPage();
  }, [page]);

  const visibleSubscribers = page.map((row) => row.original.id);
  let allChecked = true;
  for (let visibleSubscriber of visibleSubscribers) {
    if (selectedSubscribers.indexOf(visibleSubscriber) === -1) {
      allChecked = false;
      break;
    }
  }
  if (selectedSubscribers.length === 0) {
    allChecked = false;
  }

  // Render the UI for your table

  return (
    <>
      {/* table */}
      <div className="relative">
        <div className="w-full mx-auto overflow-auto bg-white scroll-firefox overflow-x-auto overflow-y-hidden">
          <table {...getTableProps()} className="w-full border-collapse">
          <TableHeader
            headerGroups={headerGroups}
            allChecked={allChecked}
            selectAll={() => selectAll(page.map((row) => row.original.id))}
            deselectAll={() => deselectAll(page.map((row) => row.original.id))}
            className="pl-0"
          />
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return <SubscribersRow key={row.id} row={row} unsubscribeButtonHandler={updateStatusHandler} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        dropdownKeys={["5", "10", "20"]}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={state.pageIndex}
        pageSize={state.pageSize}
        pageLength={page.length}
        totalLength={data.length}
      />
    </>
  );
};

export default SubscribersTable;
