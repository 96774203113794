import { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLoadScript, GoogleMap, Polygon } from "@react-google-maps/api";
import AreaAPI from "../../../../store/api/areaAPI";
import MainContainer from "../../../UI/MainContainer";
import AuthenticatedNotFound404 from "../../../pages/not-found-404/AuthenticatedNotFound404";
import BreadCrumb from "../../../UI/BreadCrumb";
import Area, { LngLat } from "../../../../types/Area";
import { AreaContext } from "../../../../store/AreaContext";
import MainFooter from "../../../UI/MainFooter";
import TextAreaInput from "../../../UI/TextAreaInput";
import TextInput from "../../../UI/TextInput";
import i18next from "i18next";
import {
  AddOrEditModal,
  MAP_CONTAINER_STYLE,
  SnackBarType,
} from "../../../../utils/Constants";
import CancelAndSaveButton from "../../../UI/CancelAndSaveButton";
import { parseCoordinates } from "../../../../utils/parseCoordinates";
import * as turf from "@turf/turf";
import { calculatePolygonCenter } from "../../../../utils/calculatePolygonCenter";
import { defaultGoogleMapCenter } from "../../../../utils/utils";

type Shape = google.maps.Polygon | google.maps.Rectangle | google.maps.Marker;

interface Coordinates {
  coordinates: LngLat[];
}

const AddOrEditArea = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const isInitialRender = useRef<Boolean>(true);
  const parentContainer = useRef<HTMLDivElement>(null);
  const { getArea, addOrEditArea, getCoordinatesList } = AreaAPI();
  const { loading } = useContext(AreaContext);
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>("");
  const [coordinates, setCoordinates] = useState<LngLat[]>([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [isUrlInvalid, setUrlInvalid] = useState(false);
  const [isOverlap, setIsOverlap] = useState(false);
  const [duplicateAreaNameErrorMsg, setDuplicateAreaNameErrorMsg] =
    useState(false);
  const mapRef = useRef<google.maps.Map>();
  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager>();
  const activeShapeRef = useRef<Shape | null>(null);
  const [coordinatesList, setCoordinatesList] = useState<Coordinates[]>([]);
  const coordinatesListRef = useRef<Coordinates[]>([]);
  const polygonRef = useRef<google.maps.Polygon | undefined>(undefined);
  const { setLoading, setSkipPageReset } = useContext(AreaContext);

  useEffect(() => {
    if (id) {
      getArea(parseInt(id), processGetArea);
    } else {
      getCoordinatesListFromServer();
    }

    if (drawingManagerRef.current) {
      google.maps.event.clearListeners(
        drawingManagerRef.current,
        "overlaycomplete"
      );
      drawingManagerRef.current.setMap(null);
    }
    const deleteControl = document.querySelector(".delete-control");
    if (deleteControl) {
      deleteControl.remove();
    }
  }, []);

  useEffect(() => {
    coordinatesListRef.current = coordinatesList;
  }, [coordinatesList]);

  const updateCoordinatesByPath = useCallback((path) => {
    const newCoordinates = path.getArray().map((p: google.maps.LatLng) => ({
      lat: p.lat(),
      lng: p.lng(),
    }));
    polygonRef.current?.setPath(newCoordinates);
    setCoordinates(newCoordinates);
  }, []);

  useEffect(() => {
    if (coordinates.length > 0 && coordinatesListRef.current.length > 0) {
      let isOverlap = false;
      for (let existsCoordinates of coordinatesListRef.current) {
        isOverlap = isPolygonsOverlap(
          coordinates,
          existsCoordinates.coordinates
        );
        if (isOverlap) break;
      }
      setIsOverlap(isOverlap);
    } else {
      setIsOverlap(false);
    }
  }, [coordinates]);

  const isPolygonsOverlap = (
    poly1: google.maps.LatLngLiteral[],
    poly2: google.maps.LatLngLiteral[]
  ) => {
    const coordinates1 = poly1.map((point) => [point.lng, point.lat]);
    const coordinates2 = poly2.map((point) => [point.lng, point.lat]);

    // Close the polygon by repeating the first point at the end
    coordinates1.push(coordinates1[0]);
    coordinates2.push(coordinates2[0]);

    const polygon1 = turf.polygon([coordinates1]);
    const polygon2 = turf.polygon([coordinates2]);

    // Check the lines
    const isOverlap = turf.booleanOverlap(polygon1, polygon2);
    // Check polygons are completely in another one or not
    const isContains1 = turf.booleanContains(polygon1, polygon2);
    const isContains2 = turf.booleanContains(polygon2, polygon1);

    return isOverlap || isContains1 || isContains2;
  };

  useEffect(() => {
    setDisableSaveBtn(
      name === undefined || name === "" || coordinates.length <= 0 || isOverlap
    );
  }, [name, coordinates, isOverlap]);

  useEffect(() => {
    if (i18next.language !== currentLanguage) {
      setCurrentLanguage(i18next.language);
      if (!isInitialRender.current) {
        window.location.reload();
      }
      isInitialRender.current = false;
    }
  }, [i18next.language]);

  // useEffect(() => {
  //   return () => {
  //     if (drawingManagerRef.current) {
  //       google.maps.event.clearListeners(drawingManagerRef.current, "overlaycomplete");
  //       drawingManagerRef.current.setMap(null);
  //     }
  //     const deleteControl = document.querySelector(".delete-control");
  //     if (deleteControl) {
  //       deleteControl.remove();
  //     }
  //   };
  // }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
    libraries: ["drawing"],
    mapIds: JSON.parse(process.env.REACT_APP_MAP_IDS!),
    language: JSON.parse(localStorage.getItem("auth_data")!).lang,
  });

  const processGetArea = (response: Response, _area: Area) => {
    if (response.status === 404) {
      setUrlInvalid(true);
      return;
    }
    setName(_area.name);
    setDescription(_area.description);
    const finalCoordinates = parseCoordinates(_area.coordinates).coordinates;
    setCoordinates(finalCoordinates);
    getCoordinatesListFromServer(finalCoordinates);
  };

  const addDeleteControl = (shape: Shape) => {
    const existingControl = document.querySelector(".delete-control");
    if (existingControl) {
      existingControl.remove();
    }

    activeShapeRef.current = shape;

    const deleteControl = document.createElement("div");
    deleteControl.innerHTML = "🗑️";
    deleteControl.className = "delete-control";
    deleteControl.style.position = "absolute";
    deleteControl.style.cursor = "pointer";
    deleteControl.style.background = "#fff";
    deleteControl.style.border = "1px solid gray";
    deleteControl.style.padding = "5px";
    deleteControl.style.fontSize = "14px";
    deleteControl.style.margin = "4px";

    if (!mapRef.current) return;
    mapRef.current.controls[google.maps.ControlPosition.TOP_CENTER].push(
      deleteControl
    );

    deleteControl.addEventListener("click", () => {
      if (activeShapeRef.current) {
        activeShapeRef.current.setMap(null);
        deleteControl.remove();
        activeShapeRef.current = null;
        setCoordinates([]);
        if (drawingManagerRef.current) {
          drawingManagerRef.current.setOptions({
            drawingControl: true,
          });
          drawingManagerRef.current.setDrawingMode(
            google.maps.drawing.OverlayType.POLYGON
          );
        }
      }
    });
  };

  const handleOverlayComplete = useCallback(
    (event: google.maps.drawing.OverlayCompleteEvent) => {
      if (activeShapeRef.current) {
        activeShapeRef.current.setMap(null);
      }

      const shape = event.overlay as Shape;
      if (!shape) return;

      shape.setMap(mapRef.current!);
      activeShapeRef.current = shape;

      google.maps.event.addListener(shape, "click", () => {
        if (!document.querySelector(".delete-control")) {
          addDeleteControl(shape);
        } else {
          activeShapeRef.current = shape;
        }
      });

      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        const polygon = shape as google.maps.Polygon;
        polygonRef.current = polygon;
        const path = polygon.getPath();

        updateCoordinatesByPath(path);
        google.maps.event.addListener(path, "set_at", () =>
          updateCoordinatesByPath(path)
        );
        google.maps.event.addListener(path, "insert_at", () =>
          updateCoordinatesByPath(path)
        );
        google.maps.event.addListener(path, "remove_at", () =>
          updateCoordinatesByPath(path)
        );

        drawingManagerRef.current!.setDrawingMode(null);
        drawingManagerRef.current!.setOptions({
          drawingControl: false,
        });
      } else if (event.type === google.maps.drawing.OverlayType.RECTANGLE) {
        const rectangle = shape as google.maps.Rectangle;
        const bounds = rectangle.getBounds()!;
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        setCoordinates([
          { lat: ne.lat(), lng: ne.lng() },
          { lat: sw.lat(), lng: ne.lng() },
          { lat: sw.lat(), lng: sw.lng() },
          { lat: ne.lat(), lng: sw.lng() },
        ]);

        google.maps.event.addListener(rectangle, "bounds_changed", () => {
          const updatedBounds = rectangle.getBounds()!;
          const updatedNe = updatedBounds.getNorthEast();
          const updatedSw = updatedBounds.getSouthWest();
          const updatedCoords = [
            { lat: updatedNe.lat(), lng: updatedNe.lng() },
            { lat: updatedSw.lat(), lng: updatedNe.lng() },
            { lat: updatedSw.lat(), lng: updatedSw.lng() },
            { lat: updatedNe.lat(), lng: updatedSw.lng() },
          ];
          setCoordinates(updatedCoords);
        });
      }
      google.maps.event.trigger(shape, 'click');
    },
    []
  );

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;

    if (drawingManagerRef.current) {
      google.maps.event.clearListeners(
        drawingManagerRef.current,
        "overlaycomplete"
      );
    }

    const shapeOptions: google.maps.PolygonOptions = {
      strokeColor: "#1C68AF",
      fillColor: "#1C68AF",
      fillOpacity: 0.25,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      clickable: true,
      draggable: true,
      editable: true,
    };

    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON], //  google.maps.drawing.OverlayType.RECTANGLE
      },
      markerOptions: { draggable: true },
      polygonOptions: { ...shapeOptions },
      rectangleOptions: { ...shapeOptions },
    });

    drawingManager.setMap(map);
    drawingManager.setDrawingMode(
      Boolean(id) ? null : google.maps.drawing.OverlayType.POLYGON
    );
    drawingManager?.setOptions({
      drawingControl: !Boolean(id),
    });
    drawingManagerRef.current = drawingManager;

    google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      handleOverlayComplete
    );
  }, []);

  const handleLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();

      const setAtListener = path.addListener("set_at", () =>
        updateCoordinatesByPath(path)
      );
      const insertAtListener = path.addListener("insert_at", () =>
        updateCoordinatesByPath(path)
      );
      const removeAtListener = path.addListener("remove_at", () =>
        updateCoordinatesByPath(path)
      );

      return () => {
        google.maps.event.removeListener(setAtListener);
        google.maps.event.removeListener(insertAtListener);
        google.maps.event.removeListener(removeAtListener);
      };
    },
    [updateCoordinatesByPath]
  );

  useEffect(() => {
    if (polygonRef.current) {
      handleLoad(polygonRef.current);
    }
  }, [coordinates, handleLoad]);

  const createTargetPolygon = () => {
    return (
      <Polygon
        key={coordinates.map((coord) => `${coord.lat},${coord.lng}`).join("|")}
        paths={Boolean(id) ? coordinates : polygonRef.current?.getPaths()}
        options={{
          strokeColor: "#1C68AF",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#1C68AF",
          fillOpacity: 0.25,
          clickable: true,
          draggable: false,
          editable: true,
        }}
        onLoad={handleLoad}
      />
    );
  };

  const arraysEqual = (a: LngLat[], b: LngLat[]) => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (a[i].lat !== b[i].lat || a[i].lng !== b[i].lng) return false;
    }
    return true;
  };

  const getCoordinatesListFromServer = (serverCoordinates?: LngLat[]) => {
    getCoordinatesList(async (response: Response) => {
      if (response.ok) {
        const result = await response.json();
        const parsedCoordinatesList = result.coordinates.map(
          (coordinate: any) => ({
            coordinates: parseCoordinates(coordinate).coordinates,
          })
        );
        const filteredCoordinatesList = parsedCoordinatesList.filter(
          (coord: Coordinates) =>
            !arraysEqual(coord.coordinates, serverCoordinates ?? coordinates)
        );
        setCoordinatesList(filteredCoordinatesList);
      } else {
        console.log("Failed to fetch coordinates");
      }
    });
  };

  const createServerPolygons = () => {
    return coordinatesList?.map((polygon, index) => (
      <Polygon
        key={index}
        paths={polygon.coordinates}
        options={{
          strokeColor: "#FF0000",
          strokeOpacity: 0.5,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.2,
        }}
      />
    ));
  };

  const onSaveBtnClicked = (_type: AddOrEditModal) => {
    setDisableSaveBtn(true);
    setLoading(true);
    addOrEditArea(
      (response) =>
        addOrEditAreaProcessResponse(
          response,
          _type === 0 ? SnackBarType.ADD : SnackBarType.EDIT
        ),
      _type,
      name!,
      description,
      coordinates,
      _type === 1 ? Number(id) : undefined
    );
  };

  const addOrEditAreaProcessResponse = async (
    response: Response,
    type: SnackBarType
  ) => {
    switch (response.status) {
      case 200:
      case 201:
        setSkipPageReset(true);
        history.replace("/areas", {
          message: id ? t("area_update_message") : t("area_add_message"),
          type: id ? SnackBarType.EDIT : SnackBarType.ADD,
        });
        break;
      case 400:
        setDuplicateAreaNameErrorMsg(true);
        break;
      default:
        history.replace("/areas", {
          message: t("area_overlap_message"),
          type: SnackBarType.ADDEDITFAILED,
        });
        break;
    }
  };

  if (isUrlInvalid) {
    return (
      <MainContainer>
        <AuthenticatedNotFound404 message={t("not_found_url__message")} />
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <main ref={parentContainer} className="alignment mb-8">
        <>
          <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
            <div className="flex items-center">
              <h3 className="text-lg font-semibold text-gray-27">
                {!id ? t("add_area") : t("edit_area")}
              </h3>
              <BreadCrumb
                className="mt-[2px] mx-2"
                availableLinks={[
                  { title: t("settings"), path: "#" },
                  { title: t("areas"), path: "/areas" },
                  {
                    title: !id ? t("add_area") : t("edit_area"),
                    path: "#",
                  },
                ]}
              />
            </div>
          </header>
          <div className="flex flex-col mt-4">
            <section className="bg-white rounded p-6">
              <div className="w-[40%] mb-6">
                <label className="text-sm text-grey-27 capitalize">
                  {t("name_title")}
                  <span className="text-red-primary"> *</span>
                </label>
                <TextInput
                  className="mt-1"
                  value={name || ""}
                  placeholder={t("name_title")}
                  hasError={name === ""}
                  errorMessage={t("name_title_is_required")}
                  onTextInputChanged={(value) => {
                    setDuplicateAreaNameErrorMsg(false);
                    setName(value);
                  }}
                />
              </div>
              <TextAreaInput
                className="h-20"
                title={t("description")}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                placeholder={t("description")}
                isRequired={false}
                hasFlag={false}
                showNumberOfChars={false}
                isRTL={i18next.language === "ar" && true}
              />
              <p className="text-sm mt-4">{t("draw_on_map")}</p>
              {isLoaded && (
                <div
                  className="w-full h-[400px] mt-2"
                  style={{ marginBottom: isOverlap ? 16 : 32 }}
                >
                  <GoogleMap
                    key={currentLanguage}
                    mapContainerStyle={MAP_CONTAINER_STYLE}
                    center={
                      Boolean(id) && coordinates.length > 0
                        ? calculatePolygonCenter(coordinates)
                        : defaultGoogleMapCenter
                    }
                    zoom={8}
                    options={{ mapId: "cb06d0000f670630" }}
                    onLoad={onMapLoad}
                  >
                    {coordinates.length > 0 && createTargetPolygon()}
                    {coordinatesList.length > 0 && createServerPolygons()}
                  </GoogleMap>
                </div>
              )}

              {isOverlap && (
                <label className="input__error-message text-base">
                  {t("overlap_err_msg")}
                </label>
              )}
              {duplicateAreaNameErrorMsg && (
                <label className="input__error-message text-base">
                  {t("area_duplicate_name")}
                </label>
              )}

              <CancelAndSaveButton
                cancelBtn={() => history.goBack()}
                disableSaveBtn={disableSaveBtn}
                saveBtn={onSaveBtnClicked}
                type={!id ? AddOrEditModal.ADD : AddOrEditModal.EDIT}
                loading={loading}
              />
            </section>
            <MainFooter />
          </div>
        </>
      </main>
    </MainContainer>
  );
};

export default AddOrEditArea;
