import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSlidersH } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

const SearchSection: React.FC<{
  onSearch: (searchInput: string) => void;
  isFilterMode?: boolean;
  onSliderClicked?: () => void;
  noFilter?: boolean;
}> = ({ onSearch, isFilterMode = false, noFilter, onSliderClicked }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (isFilterMode) {
      onSearch(searchTerm);
    }
  }, [searchTerm]);

  return (
    <section className="w-95/100 mx-auto flex justify-between items-center py-4">
      <div className="relative w-64">
        <FontAwesomeIcon
          className="absolute text-sm text-black41 left-4 top-4 "
          icon={faSearch}
        />
        <input
          className="search-filter__input w-full pl-[40px] placeholder:text-gray-d7"
          value={searchTerm}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              onSearch(searchTerm);
            }
          }}
          placeholder={t("search")}
        />
      </div>
      {!noFilter && (
        <div
          className="w-[28px] h-[28px] ml-2 rounded grid place-items-center cursor-pointer hover:bg-gray-f5"
          onClick={() => {
            onSliderClicked!();
          }}
        >
          <FontAwesomeIcon
            icon={faSlidersH}
            className="text-sm text-blue-primary"
          />
        </div>
      )}
    </section>
  );
};

export default SearchSection;
