import React, { useContext, useEffect, useState } from "react";
import Button from "../../../UI/Button";
import { ButtonTypes } from "../../../../utils/Constants";
import DatePicker from "../../../UI/DatePicker";
import Dropdown, { DropdownType } from "../../../UI/Dropdown";
import { useTranslation } from "react-i18next";
import { TrainingCandidatesContext } from "../../../../store/TrainingCandidatesContext";

export type TrainingCandidatesFilterBarType = {
  referral: string;
  dateFrom: Date | null;
  dateTo: Date | null;
  status: string;
};

export type ClinicTitle = {
  slug: string;
  name: string;
};

const TrainingCandidatesFilterBar: React.FC<{
  applyHandler: (filter: TrainingCandidatesFilterBarType) => void;
  resetHandler: () => void;
  referralValue: string;
  dateValue: { dateFrom: Date | null; dateTo: Date | null };
  statusValue: string;
}> = ({ applyHandler, resetHandler, referralValue, dateValue, statusValue }) => {
  const { t } = useTranslation();
  const [referral, setReferral] = useState("AL");
  const [status, setStatus] = useState("AL");
  const [date, setDate] = useState<{
    dateFrom: Date | null;
    dateTo: Date | null;
  }>({ dateFrom: null, dateTo: null });
  const [applyIsClicked, setApplyIsClicked] = useState(false);
  const {
    referralList,
  } = useContext(TrainingCandidatesContext);

  useEffect(() => {
    setReferral(referralValue);
  }, [referralValue]);

  useEffect(() => {
    setDate(dateValue);
  }, [dateValue]);

  useEffect(() => {
    setStatus(statusValue);
  }, [statusValue]);

  return (
    <div className="w-95/100 mx-auto flex justify-between flex-wrap">
      <div className="flex flex-col 1130:flex-row">
        {referralList.length > 0 && (
          <div className="mr-6">
            <label className="text-sm text-black41">{t("referral_source")}</label>
            <Dropdown
              type={DropdownType.PRIMARY}
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                setReferral(option);
              }}
              keys={["AL", ...referralList.map((referral) => referral[0])]}
              selectedKey={referral}
              getValueByKey={(key) => {
                if (key === "AL") return t("all");
                const foundReferral = referralList.find((referral) => referral[0] === key);
                if (foundReferral) {
                  return foundReferral[1];
                } else {
                  console.warn(`Referral with key "${key}" not found.`);
                  return "";
                }
              }}
            />
          </div>
        )}

        <div>
          <label className="text-sm text-black41">{t("contact_date")}</label>
          <div className="flex" dir="ltr">
            <DatePicker
              inputClassName="mr-3 w-[122px] placeholder:text-gray-d7"
              containerClassName="w-[122px] mr-3"
              selected={date.dateFrom}
              placeholderText={t("from")}
              onChange={(value) => {
                setDate({ ...date, dateFrom: value });
              }}
              maxDate={date.dateTo}
            />

            <DatePicker
              inputClassName="mr-3 w-[122px] placeholder:text-gray-d7"
              containerClassName="w-[122px] mr-3"
              selected={date.dateTo}
              placeholderText={t("to")}
              onChange={(value) => {
                setDate({ ...date, dateTo: value });
              }}
              minDate={date.dateFrom}
            />
          </div>
        </div>

        <div className="1130:ml-3 ml-0">
          <label className="text-sm text-black41">{t("status")}</label>
          <Dropdown
            type={DropdownType.PRIMARY}
            onOptionSelected={(option) => {
              setStatus(option);
            }}
            keys={["AL", "PD", "RS"]}
            selectedKey={status}
          />
        </div>
      </div>
      <div className="flex flex-row-reverse items-end">
        <div>
          {applyIsClicked &&
            (referral !== "AL" ||
              date.dateFrom !== null ||
              date.dateTo !== null || status !== "AL") && (
              <button
                onClick={() => {
                  resetHandler();
                  setReferral("AL");
                  setDate({ dateFrom: null, dateTo: null });
                  setStatus("AL");
                  setApplyIsClicked(false);
                }}
                className="text-blue-primary hover:font-semibold text-sm mr-6"
              >
                {t("reset")}
              </button>
            )}

          <Button
            className="mt-6"
            type={ButtonTypes.PRIMARY}
            onClick={() => {
              setApplyIsClicked(true);
              applyHandler({
                referral: referral,
                dateFrom: date.dateFrom,
                dateTo: date.dateTo,
                status: status,
              });
            }}
          >
            {t("apply")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TrainingCandidatesFilterBar;
