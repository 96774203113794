import React, { useEffect, useContext, ReactNode, useState } from "react";
import { useTable, usePagination, Column } from "react-table";
import Pagination from "../../UI/Pagination";
import ChildrenType from "../../../types/Children";
import { StaffContext } from "../../../store/StaffContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChildrenRow from "./ChildrenRow";
import i18next from "i18next";
import { VaccineStatus } from "../../../store/SearchFilterChildrenContext";
import {
  IconDefinition,
  faAngleDown,
  faAngleUp,
  faCheckCircle,
  faTimesCircle,
  faClock,
  faBan,
  faSolarSystem,
  faQuestion,
} from "@fortawesome/pro-light-svg-icons";
import { faScrubber } from "@fortawesome/pro-regular-svg-icons";
import { SortConfig } from "./Children";
import IdValueType from "../../../types/IdValueType";

const ChildrenTable: React.FC<{
  columns: Column<ChildrenType>[];
  data: ChildrenType[];
  vaccines: IdValueType[];
  vaccinesTypeAndStatus: VaccineStatus[];
  pageCount: number;
  totalDataCount: number;
  currentSortConfig: SortConfig | undefined;
  listIsLoading: boolean;
  pageChanged: (pageIndex: number, pageSize: number) => void;
  sortChanged: (sortConfig: SortConfig) => void;
}> = ({
  columns,
  data,
  vaccines,
  vaccinesTypeAndStatus,
  pageCount = 0,
  totalDataCount: totalDataCOunt,
  currentSortConfig,
  listIsLoading,
  pageChanged,
  sortChanged,
}) => {
  const { skipPageReset } = useContext(StaffContext);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { getTableProps, getTableBodyProps, headerGroups } = useTable(
    {
      columns,
      data,
      autoResetPage: skipPageReset,
    },
    usePagination
  );

  useEffect(() => {
    pageChanged(pageIndex, pageSize);
  }, [pageSize, pageIndex]);

  const selectPage = (_pageNum: number) => {
    setPageIndex(_pageNum - 1);
  };

  type IconColorPair = [IconDefinition, string?];
  const statusIconHandler = (status: string): IconColorPair => {
    const statusMap: Record<string, IconColorPair> = {
      RG: [faScrubber, "#11589a"],
      OP: [faSolarSystem, "#709bc1"],
      DC: [faBan, "#dadada"],
    };
    return statusMap[status] || [faQuestion, "#ffffff"];
  };

  const showVaccineIconHandler = (column: ReactNode) => {
    return (
      <>
        {vaccines.length !== 0 &&
          vaccines.map(
            (vaccine) =>
              vaccine.name === column &&
              vaccine.type.length !== 0 &&
              vaccine.type.map((type, i) => (
                <FontAwesomeIcon
                  key={i}
                  className="mx-1 text-[16px]"
                  style={{ color: statusIconHandler(type)[1] }}
                  icon={statusIconHandler(type)[0]}
                />
              ))
          )}
      </>
    );
  };

  const iconHandler = (iconName: string): IconDefinition => {
    switch (iconName) {
      case "faCheckCircle":
        return faCheckCircle;
      case "faTimesCircle":
        return faTimesCircle;
      case "faClock":
        return faClock;
      case "faBan":
        return faBan;
      case "faScrubber":
        return faScrubber;
      case "faSolarSystem":
        return faSolarSystem;
      default:
        return faQuestion;
    }
  };

  const sortTable = (column: string, isAscending: boolean) => {
    const vaccine = vaccines.find((vacc) => vacc.name === column);
    sortChanged({
      column: column,
      vaccineName: vaccine ? vaccine.name : undefined,
      sortType: isAscending ? "Ascending" : "Descending",
      vaccineId: vaccine?.id,
    });
  };

  return (
    <>
      <div className="relative" style={{ visibility: listIsLoading ? "hidden" : "visible" }}>
        <div
          id="staff-table__container"
          className="w-full mx-auto overflow-auto bg-white scroll-firefox overflow-x-auto overflow-y-hidden"
        >
          <table {...getTableProps()} className="w-full border-separate border-spacing-0">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-200">
                  {headerGroup.headers.map((column, index) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      scope="col"
                      className={` td-firefox border-gray-df table__cell sticky top-0 z-10 text-sm text-[#414141] font-extrabold tracking-wider ${
                        index === 0 && `z-20 ${i18next.language === "ar" ? "right-0 border-l" : "left-0 border-r"}`
                      }`}
                      {...column.getHeaderProps()} //i replace this method column.getSortByToggleProps()
                    >
                      {column.render("header") !== " " && (
                        <div onClick={() => selectPage(1)} className="flex items-center">
                          <h3 className="text-sm font-bold text-black41">{column.render("header")}</h3>
                          <div>{showVaccineIconHandler(column.render("header"))}</div>
                          <div className={` flex flex-col ${i18next.language === "ar" ? "mr-4" : "ml-4"} `}>
                            <FontAwesomeIcon
                              className={`text-sm cursor-pointer ${
                                currentSortConfig?.column === column.id && currentSortConfig?.sortType === "Ascending"
                                  ? "text-black41"
                                  : "text-gray-df"
                              }`}
                              icon={faAngleUp}
                              onClick={() => sortTable(column.id, true)}
                            />
                            <FontAwesomeIcon
                              className={`text-sm cursor-pointer ${
                                currentSortConfig?.column === column.id && currentSortConfig?.sortType === "Descending"
                                  ? "text-black41"
                                  : "text-gray-df"
                              }`}
                              icon={faAngleDown}
                              onClick={() => sortTable(column.id, false)}
                            />
                          </div>
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {data.map((child) => (
                <ChildrenRow child={child} key={child.id} columns={columns} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-row mt-6 border rounded p-2 w-fit" dir="ltr">
          {vaccinesTypeAndStatus.map(
            (type, index) =>
              type.key !== "RG" && (
                <div key={type.key || index} className="text-[12px] mr-5">
                  <FontAwesomeIcon
                    className={`mr-1 text-[16px] ${type.key === "M" && ""}`}
                    style={{ color: type.color }}
                    icon={iconHandler(type.icon)}
                  />
                  <span>{type.name}</span>
                </div>
              )
          )}
        </div>
      </div>
      {pageCount > 0 && (
        <Pagination
          dropdownKeys={["5", "10", "20"]}
          previousPage={() => setPageIndex(pageIndex - 1)}
          nextPage={() => setPageIndex(pageIndex + 1)}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          gotoPage={(_pageNum) => setPageIndex(_pageNum)}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageLength={data.length}
          totalLength={totalDataCOunt}
        />
      )}
    </>
  );
};

export default ChildrenTable;
