import { CircularProgress } from "@mui/material";
import MainContainer from "../../../UI/MainContainer";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import SearchSection from "../../../UI/SearchSection";
import MainFooter from "../../../UI/MainFooter";
import TrainingCandidatesTable from "./TrainingCandidatesTable";
import TrainingCandidatesFilterBar from "./TrainingCandidatesFilterBar";
import StatusBar from "../../../UI/StatusBar";
import { TrainingCandidatesContext } from "../../../../store/TrainingCandidatesContext";
import NoResultMessage from "../../../UI/NoResultMessage";
import { tableStringSort } from "../../../../utils/tableStringSort";
import FilterTag from "../../../UI/FilterTag";
import { getFormattedDate } from "../../../../utils/DateUtils";
import PrimaryButton from "../../../UI/PrimaryButton";

const TrainingCandidates: React.FC<{
  getTrainingCandidates: () => void;
  getReferralList: () => void;
  deleteHandler: (_id: number) => void;
  exportHandler: () => void;
}> = ({ getTrainingCandidates, deleteHandler, exportHandler }) => {
  const { t } = useTranslation();
  const isInitialRender = useRef(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showReferralFilterTag, setShowReferralFilterTag] = useState(false);
  const [showDateFromFilterTag, setShowDateFromFilterTag] = useState(false);
  const [showDateToFilterTag, setShowDateToFilterTag] = useState(false);
  const [showStatusFilterTag, setShowStatusFilterTag] = useState(false);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const {
    loading,
    responseData,
    referralList,
    searchInput,
    setSearchInput,
    filterState,
    setFilterState,
  } = useContext(TrainingCandidatesContext);

  const columns = [
    {
      header: t("full_name"),
      accessor: "first_name",
      sortType: tableStringSort,
    },
    {
      header: t("email"),
      accessor: "email",
      sortType: tableStringSort,
    },
    {
      header: t("phone"),
      accessor: "phone",
    },
    {
      header: t("clinic"),
      accessor: "clinic",
      sortType: tableStringSort,
    },
    {
      header: t("supervisor"),
      accessor: "supervisor",
      sortType: tableStringSort,
    },
    {
      header: t("referral_source"),
      accessor: "referral",
      sortType: tableStringSort,
    },
    {
      header: t("contact_date"),
      accessor: "contact_date",
    },
    {
      header: t("status"),
      accessor: "status",
    },
  ];

  let contactDateFilterTagValue =
    filterState.dateFrom && filterState.dateTo
      ? `${getFormattedDate(filterState.dateFrom)} - ${getFormattedDate(
        filterState.dateTo
      )}`
      : filterState.dateFrom
        ? `${t("from")} ${getFormattedDate(filterState.dateFrom)}`
        : filterState.dateTo
          ? `${t("to")} ${getFormattedDate(filterState.dateTo)}`
          : "";

  useEffect(() => {
    setShowLoading(true);
    if (responseData.length > 0) {
      setShowLoading(false);
    }
  }, []);

  useEffect(() => {
    setShowLoading(false);
  }, [responseData]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    setShowLoading(true);
    getTrainingCandidates();
    setShowDateFromFilterTag(Boolean(filterState.dateTo && filterState.dateFrom));
    setShowDateToFilterTag(Boolean(filterState.dateTo && filterState.dateFrom));
  }, [filterState]);

  useEffect(() => {
    if (isInitialRender.current) return;
    if (!showFilterSection) {
      setShowLoading(true);
      getTrainingCandidates();
    }
  }, [searchInput]);


  return (
    <MainContainer>
      <main className="alignment mb-8">
        <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
          <div className="flex items-center">
            <h3 className="text-lg font-semibold text-gray-27 mt-4 md:mt-0">
              {t("training_candidates")}
            </h3>
          </div>
          {responseData.length > 0 && !loading && (
            <div className="mt-2 md:mt-0">
              <PrimaryButton
                onClick={exportHandler}
                primaryOrNot
                title={t("export_to_csv")}
              />
            </div>
          )}
        </header>

        <div className="rounded shadow bg-white overflow-hidden mt-[34px] pb-6">
          <SearchSection
            onSearch={(_searchVal) => setSearchInput(_searchVal)}
            onSliderClicked={() => {
              setShowFilterSection((preState) => !preState)
            }}
            isFilterMode={showFilterSection}
          />
          <div
            className={` ${showFilterSection
              ? `${filterState.referral !== "AL" ||
                filterState.status !== "AL" ||
                filterState.dateFrom ||
                filterState.dateTo
                ? "1130:h-[150px]"
                : "1130:h-[109px]"
              } h-auto bg-white`
              : "h-0"
              }   duration-500 `}
          >
            <TrainingCandidatesFilterBar
              applyHandler={(value) => {
                setFilterState(value);
                if (value.referral !== "AL")
                  setShowReferralFilterTag(true);
                if (value.status !== "AL")
                  setShowStatusFilterTag(true);
              }}
              resetHandler={() => {
                setFilterState({
                  referral: "AL",
                  dateFrom: null,
                  dateTo: null,
                  status: "AL",
                });
                setShowReferralFilterTag(false);
                setShowDateToFilterTag(false);
                setShowDateFromFilterTag(false);
                setShowStatusFilterTag(false);
              }}
              referralValue={filterState.referral}
              dateValue={{
                dateFrom: filterState.dateFrom,
                dateTo: filterState.dateTo,
              }}
              statusValue={filterState.status}
            />
            {
              <div
                className={`flex bg-white py-4 ${(showReferralFilterTag || showDateFromFilterTag || showDateToFilterTag || showStatusFilterTag) &&
                  showFilterSection
                  ? "min-h-[69px]"
                  : "h-0"
                  } duration-500 overflow-hidden`}
              >
                <div className="mx-6 text-black41 text-sm md:flex mb-3 md:mb-0 lg:mt-0">
                  <div data-testid="applied_filter" className="mt-5">
                    {t("applied_filter")}:
                  </div>
                  {filterState?.referral !== "AL" && (
                    <FilterTag
                      label={t("referral_source")}
                      value={
                        (referralList.find((referral) => referral[0] === filterState.referral) || ["AL", ""])[1]
                      }
                      onClose={() => {
                        setShowReferralFilterTag(false);
                        setFilterState({ ...filterState, referral: "AL" });
                      }}
                    />
                  )}
                  {contactDateFilterTagValue && (
                    <FilterTag
                      label={t("contact_date")}
                      value={contactDateFilterTagValue}
                      onClose={() => {
                        setFilterState({
                          ...filterState,
                          dateFrom: null,
                          dateTo: null,
                        });
                      }}
                    />
                  )}
                  {filterState?.status !== "AL" && (
                    <StatusBar
                      deleteStatus={() => {
                        setShowStatusFilterTag(false);
                        setFilterState({ ...filterState, status: "AL" });
                      }}
                      status={filterState?.status.toLowerCase()}
                      setStatus={() => { }}
                      statusTitle="status"
                    />
                  )}
                </div>
              </div>
            }
          </div>
          <div className="line relative"></div>
          <div className="px-4 mb-[100px] relative z-10 bg-white">
            {showLoading ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} style={{ color: "#11589a" }} />
              </div>
            ) : responseData.length > 0 ? (
              <TrainingCandidatesTable
                columns={columns}
                data={responseData}
                deleteHandler={(id) => deleteHandler(id!)}
              />
            ) : (
              <div className="text-center py-2.5 rounded bg-white">
                <NoResultMessage noResultOrNoData={true} />
              </div>
            )}
          </div>
        </div>
        <MainFooter />
      </main>
    </MainContainer>
  );
};

export default TrainingCandidates;
