import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchFilterChildrenContext } from "../../../store/SearchFilterChildrenContext";
import FilterActions from "../../UI/FilterActions";
import FilterTag from "../../UI/FilterTag";
import DropdownWithCheckbox, { DropdownType, OptionType } from "../../UI/DropdownWithCheckbox";
import DropdownWithCheckboxAndIcon from "../../UI/DropdownWithCheckboxAndIcon";
import FilterTagWithIcon from "../../UI/FilterTagWithIcon";
import IdValueType from "../../../types/IdValueType";

const ChildrenFilterSection: React.FC<{
  onApply: () => void;
}> = ({ onApply }) => {
  const { t } = useTranslation();

  const {
    childrenFilter,
    setChildrenFilter,
    childrenAppliedFilter,
    setChildrenAppliedFilter,
    vaccines,
    vaccineStatuses,
    injectionStatuses,
    reset,
    isAnyFilterApplied,
  } = useContext(SearchFilterChildrenContext);

  const [isSecondDropdownDisabled, setIsSecondDropdownDisabled] = useState(false);

  useEffect(() => {
    if (childrenAppliedFilter.vaccines.length !== 0) {
      setIsSecondDropdownDisabled(!childrenAppliedFilter.vaccines?.some((item) => item.name === ""));
    }
    if (isSecondDropdownDisabled) {
      childrenFilter.vaccineStatuses = [];
    }
  }, [childrenAppliedFilter]);

  return (
    <div>
      <section className="w-95/100 mx-auto xl:flex justify-between items-center pt-1 pb-6">
        <div className="flex flex-col 960:flex-row">
          <div>
            <label className="text-sm text-black41">{t("visible_vaccine_columns")}</label>
            <DropdownWithCheckbox
              type={DropdownType.PRIMARY}
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                const isIdValueType = (option: OptionType): option is IdValueType =>
                  (option as IdValueType).id !== undefined && (option as IdValueType).name !== '';
                const filteredOptions = option.filter(isIdValueType);
                setIsSecondDropdownDisabled(filteredOptions.length !== 0);
                setChildrenFilter((prevChildrenFilter) => ({
                  ...prevChildrenFilter,
                  vaccines: filteredOptions,
                }));
              }}
              keys={[{ id: "", name: "", type: [] }, ...vaccines.map((vaccine) => vaccine)]}
              selectedKey={childrenFilter.vaccines!}
              getValueByKey={(key) => {
                if (key === "") return t("all");
                return vaccines.find((vaccine) => vaccine.name === key)?.name || '';
              }}
            />
          </div>
          <div className="960:mx-3 mx-0">
            <label className="text-sm text-black41">{t("vaccine_statuses")}</label>
            <DropdownWithCheckboxAndIcon
              type={DropdownType.PRIMARY}
              isDisabled={isSecondDropdownDisabled}
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                setChildrenFilter((preChildrenFilter) => {
                  return { ...preChildrenFilter, vaccineStatuses: option.filter((_option) => _option.name) };
                });
              }}
              keys={[{ key: "", name: "", icon: "", color: "" }, ...vaccineStatuses.map((status) => status)]}
              selectedKey={isSecondDropdownDisabled ? [] : childrenFilter.vaccineStatuses!}
              getValueByKey={(key) => {
                if (key === "") return t("all");
                return vaccineStatuses.find((status) => status.name === key)!.name;
              }}
            />
          </div>
          <div>
            <label className="text-sm text-black41">{t("injection_statuses")}</label>
            <DropdownWithCheckboxAndIcon
              type={DropdownType.PRIMARY}
              isDisabled={false}
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                setChildrenFilter((preChildrenFilter) => {
                  return { ...preChildrenFilter, injectionStatuses: option.filter((_option) => _option.name) };
                });
              }}
              keys={[{ key: "", name: "", icon: "", color: "" }, ...injectionStatuses.map((status) => status)]}
              selectedKey={childrenFilter.injectionStatuses!}
              getValueByKey={(key) => {
                if (key === "") return t("all");
                return injectionStatuses.find((status) => status.name === key)!.name;
              }}
            />
          </div>
        </div>
        <FilterActions
          isAnyFilterApplied={isAnyFilterApplied}
          reset={() => {
            reset();
            setIsSecondDropdownDisabled(false);
          }}
          onApply={onApply}
        />
      </section>

      {isAnyFilterApplied() &&
        ((childrenAppliedFilter.vaccines?.length !== 0 && !childrenAppliedFilter.vaccines.some((item) => item.name === "")) ||
          (childrenAppliedFilter.vaccineStatuses?.length !== 0 &&
            !childrenAppliedFilter.vaccineStatuses.some((item) => item.name === "")) ||
          (childrenAppliedFilter.injectionStatuses?.length !== 0 &&
            !childrenAppliedFilter.injectionStatuses.some((item) => item.name === ""))) && (
          <section className="w-95/100 mx-auto py-4 flex flex-wrap items-center">
            <p className="capitalize mt-1 text-sm text-black41">{t("applied_filter")}:</p>
            {childrenAppliedFilter.vaccines?.length !== 0 && !childrenAppliedFilter.vaccines.some((item) => item.name === "") && (
              <FilterTag
                label={t("visible_vaccine_columns")}
                value={childrenAppliedFilter.vaccines!.map((key) => key.name).join(", ")}
                onClose={() => {
                  setChildrenAppliedFilter((preChildrenAppliedFilter) => {
                    setIsSecondDropdownDisabled(false);
                    return {
                      ...preChildrenAppliedFilter,
                      vaccines: [],
                    };
                  });
                }}
              />
            )}
            {!isSecondDropdownDisabled &&
              childrenAppliedFilter.vaccineStatuses?.length !== 0 &&
              !childrenAppliedFilter.vaccineStatuses.some((item) => item.name === "") && (
                <FilterTagWithIcon
                  label={t("vaccine_statuses")}
                  value={childrenAppliedFilter.vaccineStatuses!}
                  onClose={() => {
                    setChildrenAppliedFilter((preChildrenAppliedFilter) => {
                      return {
                        ...preChildrenAppliedFilter,
                        vaccineStatuses: [],
                      };
                    });
                  }}
                />
              )}
            {childrenAppliedFilter.injectionStatuses?.length !== 0 &&
              !childrenAppliedFilter.injectionStatuses.some((item) => item.name === "") && (
                <FilterTagWithIcon
                  label={t("injection_statuses")}
                  value={childrenAppliedFilter.injectionStatuses!}
                  onClose={() => {
                    setChildrenAppliedFilter((preChildrenAppliedFilter) => {
                      return {
                        ...preChildrenAppliedFilter,
                        injectionStatuses: [],
                      };
                    });
                  }}
                />
              )}
          </section>
        )}
    </div>
  );
};

export default ChildrenFilterSection;
