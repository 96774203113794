import { CONNREFUSED } from "dns";
import useAPI from "../../hooks/useAPI";
import TrainingCandidateDetailsType from "../../types/TrainingCandidateDetails";

const TrainingCandidatesApi = () => {
  const { sendRequest } = useAPI();

  const getTrainingCandidates = (trainingCandidateProcessResponse: (response: Response) => void, url: string) => {
    sendRequest(
      {
        url: url,
      },
      trainingCandidateProcessResponse
    );
  };

  const deleteTrainingCandidate = (trainingCandidateProcessResponse: (response: Response) => void, _id: number) => {
    sendRequest(
      {
        url: `training-candidate/${_id}/`,
        method: "DELETE",
      },
      trainingCandidateProcessResponse
    );
  };

  const exportTrainingCandidates = (queryParams: string, exportPreccessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: `training-candidate/export/${queryParams}`,
        method: "GET",
      },
      exportPreccessResponse
    );
  };

  const getTrainingCandidateDetails = (
    id: number,
    trainingCandidateProcessResponse: (response: Response, trainingCandidate: TrainingCandidateDetailsType) => void
  ) => {
    sendRequest(
      {
        url: `training-candidate/${id}/`,
      },
      async (response) => {
        const result = await response.json();

        trainingCandidateProcessResponse(response, {
          fullName: result.first_name + " " + result.last_name,
          email: result.email,
          phone: result.phone,
          contactDate: result.contact_date,
          clinic: result.clinic,
          supervisor: result.supervisor,
          address: result.postal_code,
          postalCode: result.address,
          country: result.country_id,
          city: result.city,
          referral: result.referral,
          message: result.message,
          status: result.status,
        });
      }
    );
  };

  const updateStatus = (
    trainingCandidateProcessResponse: (response: Response) => void,
    _id: number,
    _status: string
  ) => {
    sendRequest(
      {
        url: `training-candidate/${_id}/`,
        method: "PATCH",
        body: JSON.stringify({
          status: _status,
        }),
      },
      trainingCandidateProcessResponse
    );
  };

  const getCountryList = (getListProcessResponse: (items: any) => void) => {
    sendRequest(
      {
        url: "country-list/",
      },
      getListProcessResponse
    );
  };

  return { getTrainingCandidates, deleteTrainingCandidate, exportTrainingCandidates, getTrainingCandidateDetails, updateStatus, getCountryList };
};

export default TrainingCandidatesApi;
