import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { AuthContext } from "../store/AuthContext";

const PrivateRoute: React.FC<{ path: string; exact: boolean }> = (props) => {
  const { authData } = useContext(AuthContext);

  return (
    <Route path={props.path} exact={props.exact}>
      {authData.access !== "" && authData.refresh !== "" ? (
        props.children
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  );
};

export default PrivateRoute;
