import MainContainer from "../../../UI/MainContainer";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../UI/PrimaryButton";
import { useContext, useEffect, useRef, useState } from "react";
import SearchSection from "../../../UI/SearchSection";
import MainFooter from "../../../UI/MainFooter";
import SubscribersTable from "./SubscribersTable";
import SubscriberFilterBar from "./SubscriberFilterBar";
import StatusBar from "../../../UI/StatusBar";
import { SubscriberContext } from "../../../../store/SubscriberContext";
import DateBox from "../../../UI/DateBox";
import { getDate } from "../../../../utils/getDate";
import NoResultMessage from "../../../UI/NoResultMessage";
import { CircularProgress } from "@mui/material";
import Subscriber from "../../../../types/Subscriber";
import { Row } from "react-table";
import { tableStringSort } from "../../../../utils/tableStringSort";
import FilterTag from "../../../UI/FilterTag";
import { getFormattedDate } from "../../../../utils/DateUtils";

const Subscribers: React.FC<{
  getSubscribers: () => void;
  exportSubscribersHandler: () => void;
  updateStatusHandler: (_id: string) => void;
}> = ({ getSubscribers, exportSubscribersHandler, updateStatusHandler }) => {
  const { t } = useTranslation();
  const isInitialRender = useRef(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [showStatusBar, setShowStatusBar] = useState(false);
  const [showDateFrom, setShowDateFrom] = useState(false);
  const [showDateTo, setShowDateTo] = useState(false);
  const {
    responseData,
    searchInput,
    setSearchInput,
    filterState,
    setFilterState,
  } = useContext(SubscriberContext);

  const columns = [
    {
      header: t("email"),
      accessor: "email",
      sortType: tableStringSort,
    },
    {
      header: t("first_name"),
      accessor: "first_name",
      sortType: tableStringSort,
    },
    {
      header: t("last_name"),
      accessor: "last_name",
      sortType: tableStringSort,
    },
    {
      header: t("subscription_date"),
      accessor: "subscription_date",
      sortType: (
        rowA: Row<Subscriber>,
        rowB: Row<Subscriber>,
        columnId: string
      ) => {
        const parseDate = (dateStr: string) => {
          if (!dateStr) return null;
          const parts = dateStr.split("/");
          return new Date(
            parseInt(parts[2], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[0], 10)
          );
        };
        let dateA = parseDate(rowA.values[columnId]);
        let dateB = parseDate(rowB.values[columnId]);

        if (dateA === null && dateB !== null) return 1;
        if (dateB === null && dateA !== null) return -1;
        if (dateA === null && dateB === null) return 0;
        return dateA!.getTime() - dateB!.getTime();
      },
    },
    {
      header: t("status"),
      accessor: "status",
    },
  ];

  let subscriptionDateFilterTagValue =
    filterState.dateFrom && filterState.dateTo
      ? `${getFormattedDate(filterState.dateFrom)} - ${getFormattedDate(
          filterState.dateTo
        )}`
      : filterState.dateFrom
      ? `${t("from")} ${getFormattedDate(filterState.dateFrom)}`
      : filterState.dateTo
      ? `${t("to")} ${getFormattedDate(filterState.dateTo)}`
      : "";

  useEffect(() => {
    setShowLoading(true);
    if (responseData.length > 0) {
      setShowLoading(false);
    }
  }, []);

  useEffect(() => {
    setShowLoading(false);
  }, [responseData]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    setShowLoading(true);
    getSubscribers();
    setShowDateFrom(Boolean(filterState.dateTo && filterState.dateFrom));
    setShowDateTo(Boolean(filterState.dateTo && filterState.dateFrom));
  }, [filterState]);

  useEffect(() => {
    if (isInitialRender.current) return;
    if (!showFilterSection) {
      setShowLoading(true);
      getSubscribers();
    }
  }, [searchInput]);

  return (
    <MainContainer>
      <div className="alignment mb-8">
        <div className="md:flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
          <h3 className="text-lg font-semibold text-gray-27">
            {t("subscribers")}
          </h3>
          {responseData.length > 0 && (
            <div className="mt-2 md:mt-0">
              <PrimaryButton
                onClick={exportSubscribersHandler}
                primaryOrNot
                title={t("export_to_csv")}
              />
            </div>
          )}
        </div>
        <main>
          <div className="rounded shadow bg-white overflow-hidden mt-[34px]">
            <SearchSection
              onSearch={(_searchVal) => setSearchInput(_searchVal)}
              onSliderClicked={() =>
                setShowFilterSection((preState) => !preState)
              }
              isFilterMode={showFilterSection}
            />
            <div
              className={` ${
                showFilterSection
                  ? `${
                      filterState?.status !== "AL" ||
                      filterState.dateFrom ||
                      filterState.dateTo
                        ? "md:h-[150px]"
                        : "md:h-[109px]"
                    } h-auto bg-white`
                  : "h-0"
              }   duration-500 `}
            >
              {showFilterSection && (
                <SubscriberFilterBar
                  applyHandler={(value) => {
                    setFilterState(value);
                    setShowStatusBar(true);
                  }}
                  resetHandler={() => {
                    setFilterState({
                      dateFrom: null,
                      dateTo: null,
                      status: "AL",
                    });
                    setShowStatusBar(false);
                    setShowDateTo(false);
                    setShowDateFrom(false);
                  }}
                  statusValue={filterState.status}
                  dateValue={{
                    dateFrom: filterState.dateFrom,
                    dateTo: filterState.dateTo,
                  }}
                />
              )}
              {
                <div
                  className={`flex bg-white py-4 ${
                    (showStatusBar || showDateTo || showDateFrom) &&
                    showFilterSection
                      ? "min-h-[69px]"
                      : "h-0"
                  } duration-500 overflow-hidden`}
                >
                  <div className="mx-6 text-black41 text-sm md:flex mb-3 md:mb-0 lg:mt-0">
                    <div data-testid="applied_filter" className="mt-5">
                      {t("applied_filter")}:
                    </div>
                    {filterState?.status !== "AL" && (
                      <StatusBar
                        deleteStatus={() => {
                          setShowStatusBar(false);
                          setFilterState({ ...filterState, status: "AL" });
                        }}
                        status={filterState?.status.toLowerCase()}
                        setStatus={() => {}}
                        statusTitle="status"
                      />
                    )}
                    {subscriptionDateFilterTagValue && (
                      <FilterTag
                        label={t("subscription_date")}
                        value={subscriptionDateFilterTagValue}
                        onClose={() => {
                          setFilterState({
                            ...filterState,
                            dateFrom: null,
                            dateTo: null,
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              }
            </div>
            <div className="line relative"></div>
            <div className="px-4 mb-[100px] relative z-10 bg-white">
              {showLoading ? (
                <div className="flex w-full h-[500px] items-center justify-center">
                  <CircularProgress size={64} style={{ color: "#11589a" }} />
                </div>
              ) : responseData.length > 0 ? (
                <SubscribersTable
                  columns={columns}
                  data={responseData}
                  editHandler={() => {}}
                  updateStatusHandler={(id) => updateStatusHandler(id)}
                />
              ) : (
                <div className="text-center py-2.5 rounded bg-white">
                  <NoResultMessage noResultOrNoData={true} />
                </div>
              )}
            </div>
          </div>
          <MainFooter />
        </main>
      </div>
    </MainContainer>
  );
};

export default Subscribers;
