import { Snackbar, SnackbarContent } from "@mui/material";
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import SubscribersApi from "../../../store/api/subscriberApi";
import { SubscriberContext } from "../../../store/SubscriberContext";
import { saveAs } from "file-saver";
import { getDate } from "../../../utils/getDate";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { SnackBarType } from "../../../utils/Constants";
import i18next from "i18next";
import { SelectedSubscribersContext } from "../../../store/SelectedSubscribersContext";

export const subscribersLoader = (Component: any) => {
  return (props: any) => {
    const { t } = useTranslation();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [notFoundMessage, setNotFoundMessage] = useState("");
    const { getSubscriber, exportSubscribers, updateStatus } = SubscribersApi();
    const { setResponseData, responseData, setLoading, searchInput, filterState } = useContext(SubscriberContext);

    const [exporting, setExporting] = useState(false);
    const [snackBarType, setSnackBarType] = useState<SnackBarType>();

    const { selectedSubscribers, clearSelectedSubscribers } = useContext(SelectedSubscribersContext);

    const exportSubscribersHandler = () => {
      exportSubscribers(selectedSubscribers.map((selectedSubscriber) => ({ slug: selectedSubscriber })), exportPreccessResponse, url());
    };

    const exportPreccessResponse = async (response: Response) => {
      if (response.ok) {
        setExporting(true);
        setTimeout(async () => {
          try {
            const blob = await response.blob();
            saveAs(blob, ` "subscribers".csv`);
            setSnackBarType(SnackBarType.DOWNLOAD);
            setExporting(false);
            setShowSnackbar(true);
            clearSelectedSubscribers();
          } catch (e) {
            console.log("error:file not found");
          } finally {
            setExporting(false);
          }
        }, 1000);
      }
    };

    const url = () => {
      let url: string = "";

      if (searchInput === "" && filterState.status === "AL" && filterState.dateFrom === null && filterState.dateTo === null) {
        url = `subscribers/`;
      } else if (searchInput !== "" && filterState.status === "AL" && filterState.dateFrom === null && filterState.dateTo === null) {
        url = `subscribers/?search_subscriber=${searchInput}`;
      } else if (searchInput !== "" && filterState.status === "AL" && filterState.dateFrom === null && filterState.dateTo !== null) {
        url = `subscribers/?search_subscriber=${searchInput}&subscriber_to=${getDate(filterState.dateTo.toString(), false)}`;
      } else if (searchInput === "" && filterState.status !== "AL" && filterState.dateFrom !== null && filterState.dateTo === null) {
        url = `subscribers/?subscriber_status=${filterState.status}&subscriber_from=${getDate(filterState.dateFrom.toString(), false)}`;
      } else if (searchInput !== "" && filterState.status === "AL" && filterState.dateFrom !== null && filterState.dateTo !== null) {
        url = `subscribers/?search_subscriber=${searchInput}&subscriber_from=${getDate(
          filterState.dateFrom.toString(),
          false
        )}&subscriber_to=${getDate(filterState.dateTo.toString(), false)}`;
      } else if (searchInput !== "" && filterState.status !== "AL" && filterState.dateFrom !== null && filterState.dateTo === null) {
        url = `subscribers/?search_subscriber=${searchInput}&subscriber_status=${filterState.status}&subscriber_from=${getDate(
          filterState.dateFrom.toString(),
          false
        )}`;
      } else if (searchInput !== "" && filterState.status === "AL" && filterState.dateFrom !== null && filterState.dateTo === null) {
        url = `subscribers/?search_subscriber=${searchInput}&subscriber_from=${getDate(filterState.dateFrom.toString(), false)}`;
      } else if (searchInput !== "" && filterState.status !== "AL" && filterState.dateFrom === null && filterState.dateTo !== null) {
        url = `subscribers/?search_subscriber=${searchInput}&subscriber_status=${filterState.status}&subscriber_to=${getDate(
          filterState.dateTo.toString(),
          false
        )}`;
      } else if (searchInput !== "" && filterState.status !== "AL" && filterState.dateFrom === null && filterState.dateTo === null) {
        url = `subscribers/?search_subscriber=${searchInput}&subscriber_status=${filterState.status}`;
      } else if (searchInput === "" && filterState.status !== "AL" && filterState.dateFrom === null && filterState.dateTo === null) {
        url = `subscribers/?subscriber_status=${filterState.status}`;
      } else if (searchInput === "" && filterState.status === "AL" && filterState.dateFrom !== null && filterState.dateTo === null) {
        url = `subscribers/?subscriber_from=${getDate(filterState.dateFrom.toString(), false)}`;
      } else if (searchInput === "" && filterState.status === "AL" && filterState.dateTo !== null && filterState.dateFrom === null) {
        url = `subscribers/?subscriber_to=${getDate(filterState.dateTo.toString(), false)}`;
      } else if (searchInput === "" && filterState.status === "AL" && filterState.dateTo !== null && filterState.dateFrom !== null) {
        url = `subscribers/?subscriber_from=${getDate(filterState.dateFrom.toString(), false)}&subscriber_to=${getDate(
          filterState.dateTo.toString(),
          false
        )}`;
      } else if (searchInput === "" && filterState.status !== "AL" && filterState.dateTo !== null && filterState.dateFrom !== null) {
        url = `subscribers/?subscriber_status=${filterState.status}&subscriber_from=${getDate(
          filterState.dateFrom.toString(),
          false
        )}&subscriber_to=${getDate(filterState.dateTo.toString(), false)}`;
      } else if (searchInput !== "" && filterState.status !== "AL" && filterState.dateTo !== null && filterState.dateFrom !== null) {
        url = `subscribers/?search_subscriber=${searchInput}&subscriber_status=${filterState.status}&subscriber_from=${getDate(
          filterState.dateFrom.toString(),
          false
        )}&subscriber_to=${getDate(filterState.dateTo.toString(), false)}`;
      } else if (searchInput === "" && filterState.status !== "AL" && filterState.dateTo !== null && filterState.dateFrom === null) {
        url = `subscribers/?subscriber_status=${filterState.status}&subscriber_to=${getDate(filterState.dateTo.toString(), false)}`;
      }

      return url;
    }

    const getSubscribers = () => {
      
      getSubscriber(processGetSubscribersResponse, url());
    };
    const processGetSubscribersResponse = async (response: Response) => {
      const result = await response.json();
      if (response.ok) {
        setResponseData(result);
      } else {
        setNotFoundMessage(result.clinic);
      }
    };
    const updateStatusHandler = (_id: string) => {
      updateStatus(async (response) => updateProcessResponse(response, _id), _id);
    };

    const updateProcessResponse = async (response: Response, _id: string) => {
      setLoading(true);
      if (response.ok) {
        const result = await response.json();
        const newData = [...responseData];
        const index = responseData.findIndex((data) => data.id === _id);
        newData[index] = {
          email: result.email,
          first_name: result.first_name,
          id: result.id,
          last_name: result.last_name,
          status: result.status,
          subscription_date: result.subscription_date,
          unsubscription_date: result.unsubscription_date,
        };
        setResponseData(newData);
        setLoading(false);
        setSnackBarType(SnackBarType.DELETE);
        setShowSnackbar(true);
      }
    };
    return (
      <>
        <Snackbar
          dir={i18next.language === "ar" ? "rtl": "ltr"}
          open={showSnackbar}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "#03A678",
            },
            "& .MuiSnackbarContent-message": {
              fontSize: "16px",
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: "100",
            },
          }}
          onClose={() => setShowSnackbar(false)}
        >
          <SnackbarContent message={<p>
            {snackBarType === SnackBarType.DELETE && t("subscriber__message")}
            {snackBarType === SnackBarType.DOWNLOAD && <>{t("data_exported_successfully")}</>}
          </p>} />
        </Snackbar>
        {exporting && (
					<LoadingSpinner
						onBackdrop={() => setExporting(false)}
						titleKey={t("exporting_to_csv")}
					/>
				)}
        <Component
          {...props}
          notFoundMessage={notFoundMessage}
          getSubscribers={getSubscribers}
          exportSubscribersHandler={exportSubscribersHandler}
          updateStatusHandler={updateStatusHandler}
        />
      </>
    );
  };
};
