import React, { useContext, useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import Pagination from "../../../UI/Pagination";
import TrainingCandidatesRow from "./TrainingCandidatesRow";
import TrainingCandidate from "../../../../types/TrainingCandidate";
import { SelectedTrainingCandidatesContext } from "../../../../store/SelectedTrainingCandidatesContext";

interface Column {
  header: string;
  accessor: any;
}

const TrainingCandidatesTable: React.FC<{
  columns: Column[];
  data: TrainingCandidate[];
  deleteHandler: (_id: number) => void;
}> = ({ columns, data, deleteHandler }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
    },

    useSortBy,
    usePagination
  );

  const { selectedTrainingCandidates, selectAll, deselectAll } = useContext(
    SelectedTrainingCandidatesContext
  );

  useEffect(() => {
    page.length === 0 && previousPage();
  }, [page]);

  const selectPage = (_pageNum: number) => {
    gotoPage(_pageNum - 1);
  };

  const visibleTrainingCandidates = page.map((row) => row.original.id);
  let allChecked = true;
  for (let visibleTrainingCandidate of visibleTrainingCandidates) {
    if (selectedTrainingCandidates.indexOf(visibleTrainingCandidate) === -1) {
      allChecked = false;
      break;
    }
  }
  if (selectedTrainingCandidates.length === 0) {
    allChecked = false;
  }

  return (
    <>
      <div className="relative">
        <div
          id="training_candidates-table__container"
          className="w-full mx-auto overflow-auto bg-white scroll-firefox overflow-x-auto overflow-y-hidden"
        >
          <table {...getTableProps()} className="border-collapse w-full">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="border-b border-gray-200"
                >
                  {headerGroup.headers.map((column, index) => (
                    <th
                      scope="col"
                      className={`border-b td-firefox border-gray-df table__cell sticky top-0 z-10 text-sm text-[#414141] font-extrabold tracking-wider ${
                        index === 0 && `z-20 left-0`
                      }`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex">
                        {column.render("header") !== " " && (
                          <div
                            onClick={() => selectPage(1)}
                            className="flex items-center"
                          >
                            {index === 0 && (
                              <div
                                className={`table__checkbox grid place-items-center ${
                                  allChecked &&
                                  "border-blue-primary bg-blue-primary"
                                } mr-4`}
                                onClick={(e) => {
                                  e.stopPropagation();

                                  if (allChecked) {
                                    deselectAll(
                                      page.map((row) => row.original.id)
                                    );
                                  } else {
                                    selectAll(
                                      page.map((row) => row.original.id)
                                    );
                                  }
                                }}
                              >
                                {allChecked && (
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className="text-sm text-white"
                                  />
                                )}
                              </div>
                            )}
                            <h3 className="text-sm font-bold text-black41">
                              {column.render("header")}
                            </h3>

                            <div className="flex flex-col-reverse ml-4">
                              <FontAwesomeIcon
                                className={`text-sm ${
                                  column.isSorted && column.isSortedDesc
                                    ? "text-black41"
                                    : "text-gray-df"
                                }`}
                                icon={faAngleDown}
                              />
                              <FontAwesomeIcon
                                className={`text-sm rotate-180 ${
                                  column.isSorted && !column.isSortedDesc
                                    ? "text-black41"
                                    : "text-gray-df"
                                }`}
                                icon={faAngleDown}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <TrainingCandidatesRow
                    key={row.id}
                    row={row}
                    deleteHandler={(id) => deleteHandler(id)}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        dropdownKeys={["5", "10", "20"]}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={state.pageIndex}
        pageSize={state.pageSize}
        pageLength={page.length}
        totalLength={data.length}
      />
    </>
  );
};

export default TrainingCandidatesTable;
