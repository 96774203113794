import React from "react";

import { ButtonTypes } from "../../utils/Constants";

const Button: React.FC<{
  type: ButtonTypes;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  dataAction?: string;
}> = (props) => {
  const { type, onClick, className, children, disabled, dataAction } = props;

  let classes = "";
  switch (type) {
    case ButtonTypes.PRIMARY:
      classes =
        "bg-blue-primary text-white hover:bg-blue-secondary disabled:bg-blue-71";
      break;
    case ButtonTypes.SECONDARY:
      classes =
        "bg-white text-blue-primary border border-solid border-blue-primary hover:bg-blue-primary hover:text-white";
      break;
    case ButtonTypes.CANCEL:
      classes = "bg-white text-black41 hover:bg-gray-f5";
      break;
    case ButtonTypes.ERROR:
      classes = "bg-red-primary text-white hover:bg-red-secondary";
      break;
  }

  return (
    <button
      data-action={dataAction}
      className={` first-letter:capitalize text-sm py-3 px-8 rounded duration-500 ${classes} ${className}`}
      onClick={() => {
        if (!disabled) onClick?.();
      }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
