import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import Dropdown, { DropdownType } from "./Dropdown";
import Button from "./Button";
import { ButtonTypes } from "../../utils/Constants";

const MessageSection: React.FC<{
  className?: string;
  title: string;
  message: string;
  date: string;
  status: string;
  onSave: (newStatus: string) => void;
}> = ({ className, title, message, date, status, onSave }) => {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(true);
  const [trainingCandidateStatus, setTrainingCandidateStatus] = useState(status);
  const parentContainer = useRef<HTMLDivElement>(null);
  const childContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showInfo) {
      parentContainer.current!.style.height = "auto";
    } else {
      parentContainer.current!.style.height = "0px";
    }
  }, [showInfo]);

  const onSaveButtonClicked = () => {
    onSave(trainingCandidateStatus);
  };

  return (
    <section className={`bg-white rounded ${className}`}>
      <div
        className={`py-4 px-6 flex justify-between items-center rounded-t bg-gray-612d duration-700 ${
          !showInfo && "rounded-b"
        }`}
      >
        <p
          className={`font-semibold ${
            showInfo ? "text-black41" : "text-blue-secondary"
          }`}
        >
          {title}
        </p>
        <FontAwesomeIcon
          icon={faAngleUp}
          className={`text-sm text-gray-8d cursor-pointer duration-300 ${
            showInfo ? "rotate-0" : "-rotate-180"
          }`}
          onClick={() => setShowInfo((preShowInfo) => !preShowInfo)}
        />
      </div>
      <div ref={parentContainer} className="duration-300 overflow-hidden">
        <div className="gap-10 p-6" ref={childContainer}>
          <article className="text-sm text-black41">
            <p className="font-semibold">{message}</p>
            <p className="text-right mt-4">{date}</p>
          </article>
          <div className="mt-6">
            <label className="text-sm text-black41">{t("status")}</label>
            <Dropdown
              type={DropdownType.PRIMARY}
              onOptionSelected={(option) => {
                setTrainingCandidateStatus(option);
              }}
              keys={["AL", "PD", "RS"]}
              selectedKey={trainingCandidateStatus}
            />
          </div>
          <div className="mt-14 relative text-right">
            <Button
              children={t("save")}
              onClick={onSaveButtonClicked}
              type={ButtonTypes.PRIMARY}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MessageSection;
