import React, { useState } from "react";

type AccountSettingContextType = {
  currentPasswordHasError: boolean;
  setCurrentPasswordHasError: (status: boolean) => void;
  setPasswordErrorText: (inputText: string) => void;
  passwordErrorText: string;
  setLoading: (status: boolean) => void;
  loading: boolean;
};
export const AccountSettingContext =
  React.createContext<AccountSettingContextType>({
    currentPasswordHasError: false,
    setCurrentPasswordHasError: () => {
      //
    },
    setPasswordErrorText: () => {
      //
    },
    passwordErrorText: "",
    setLoading: () => {
      //
    },
    loading: false,
  });

const AccountSettingContextProvider: React.FC = ({ children }) => {
  const [currentPasswordHasError, setCurrentPasswordHasError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  return (
    <AccountSettingContext.Provider
      value={{
        currentPasswordHasError,
        setCurrentPasswordHasError,
        setPasswordErrorText,
        passwordErrorText,
        setLoading,
        loading,
      }}
    >
      {children}
    </AccountSettingContext.Provider>
  );
};
export default AccountSettingContextProvider;
