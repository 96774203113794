import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { VaccineStatus } from "../../store/SearchFilterChildrenContext";
import { IconDefinition, faQuestion } from "@fortawesome/pro-regular-svg-icons";
import {
  faAngleDown,
  faCheckCircle,
  faTimesCircle,
  faClock,
  faBan,
  faSolarSystem,
} from "@fortawesome/pro-light-svg-icons";
import { faScrubber } from "@fortawesome/pro-regular-svg-icons";
import i18next from "i18next";

export enum DropdownType {
  PRIMARY,
  SECONDARY,
}

const Dropdown: React.FC<{
  type: DropdownType;
  isDisabled: boolean;
  keys: VaccineStatus[];
  selectedKey: VaccineStatus[];
  onOptionSelected: (key: VaccineStatus[]) => void;
  getValueByKey?: (key: string) => string;
  extraAvailableOptionsContainerClassName?: string;
}> = ({
  type,
  isDisabled,
  keys,
  selectedKey,
  onOptionSelected,
  getValueByKey,
  extraAvailableOptionsContainerClassName,
}) => {
  let rootElementclassName,
    selectedOptionClassName,
    selectedOptionDefaultState,
    selectedOptionClickedState,
    selectedOptionTextClassName: string | undefined,
    availableOptionsClassName: string,
    availableOptionsContainerClassName;

  switch (type) {
    case DropdownType.PRIMARY:
      rootElementclassName = "w-64";
      selectedOptionClassName =
        "flex justify-between items-center pt-3 px-4 border border-gray-df rounded cursor-pointer hover:bg-white";
      selectedOptionDefaultState = "bg-gray-f6";
      selectedOptionClickedState = "border-blue-71 bg-white";
      selectedOptionTextClassName = "text-sm capitalize text-black41";
      availableOptionsClassName =
        "px-2 py-2 capitalize text-sm text-black41 cursor-pointer hover:bg-gray-f6";
      availableOptionsContainerClassName = "top-12";
      break;
    case DropdownType.SECONDARY:
      rootElementclassName = "w-[72px]";
      selectedOptionClassName =
        "flex justify-between items-center p-2 border border-gray-df rounded cursor-pointer";
      selectedOptionDefaultState = "bg-white";
      selectedOptionClickedState = "border-blue-71 bg-white";
      selectedOptionTextClassName = "text-black41 text-sm";
      availableOptionsClassName =
        "p-2 capitalize text-sm text-black41 cursor-pointer hover:bg-gray-f6";
      availableOptionsContainerClassName = "top-10";
      break;
  }

  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (key: VaccineStatus) => {
    if (key.name === "") {
      onOptionSelected([key]);
    } else {
      if (selectedKey.some((item) => item.name === "")) {
        selectedKey = selectedKey.filter((item) => item.name !== "");
      }
      if (selectedKey.includes(key)) {
        onOptionSelected(
          selectedKey.filter((selectedKey) => selectedKey !== key)
        );
      } else {
        onOptionSelected([...selectedKey, key]);
      }
    }
  };

  const showKeyHandler = (key: VaccineStatus) => {
    return (
      <>
        {keys.length !== 0 &&
          key.name !== "" &&
            <FontAwesomeIcon
              key={key.key}
              className={`${i18next.language === "ar" ? "ml-1" : "mr-1"} text-[16px]`}
              style={{ color: key.color }}
              icon={iconHandler(key.icon)}
            />
          }
        {getValueByKey ? getValueByKey(key.name) : t(key.name.toLowerCase())}
      </>
    );
  };

  const iconHandler = (iconName: string): IconDefinition => {
    switch (iconName) {
      case "faCheckCircle":
        return faCheckCircle;
      case "faTimesCircle":
        return faTimesCircle;
      case "faClock":
        return faClock;
      case "faBan":
        return faBan;
      case "faScrubber":
        return faScrubber;
      case "faSolarSystem":
        return faSolarSystem;

      default:
        return faQuestion;
    }
  };

  const handleClick = () => {
    if (!isDisabled) {
      setShowMenu((preShowOptions) => !preShowOptions);
    }
  };

  return (
    <div
      className={`relative ${rootElementclassName}`}
      tabIndex={0}
      ref={dropdownRef}
      onBlur={() => !isDisabled}
    >
      <div
        className={`${selectedOptionClassName} ${showMenu ? selectedOptionClickedState : selectedOptionDefaultState} ${isDisabled && 'opacity-50 cursor-not-allowed'}`}
        onClick={handleClick}
      >
        <div className={`${selectedOptionTextClassName} h-8 overflow-y-hidden overflow-x-auto whitespace-nowrap mr-2`}>
          {selectedKey.length === 0
            ? getValueByKey
              ? getValueByKey("")
              : t("AL".toLowerCase())
            : selectedKey.map((key, index) => (
                <span key={key.key || index} className="inline-block">
                  {showKeyHandler(key)}
                  {index < selectedKey.length - 1 && (
                    <span className={i18next.language === "ar" ? "ml-1" : "mr-1"}>,</span>
                  )}
                </span>
              ))}
        </div>
        <FontAwesomeIcon className="text-gray-b5 mb-2" icon={faAngleDown} />
      </div>
      {showMenu && !isDisabled && (
        <ul
          className={`absolute left-0 right-0 bg-white rounded shadow py-1 z-30 ${availableOptionsContainerClassName} ${extraAvailableOptionsContainerClassName}`}
        >
          {keys.map((key) => (
            <li
              key={key.name}
              className={availableOptionsClassName}
              onClick={() => {
                handleOptionClick(key);
              }}
            >
              <input
                type="checkbox"
                className="cursor-pointer mx-2"
                checked={selectedKey.includes(key)}
                onChange={() => {
                  handleOptionClick(key);
                }}
              />
              {showKeyHandler(key)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
