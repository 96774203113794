import React, { useContext, useEffect, useState } from "react";
import Button from "../../../UI/Button";
import { ButtonTypes } from "../../../../utils/Constants";
import DatePicker from "../../../UI/DatePicker";
import { DropdownType } from "../../../UI/Dropdown";
import { useTranslation } from "react-i18next";
import DropdownWithCheckbox from "../../../UI/DropdownWithCheckbox";
import IdValueType from "../../../../types/IdValueType";
import { VaccineUtilizationContext } from "../../../../store/VaccineUtilizationContext";
import i18next from "i18next";

export type VaccineUtilizationFilterBarType = {
  vaccine: IdValueType[];
  dateFrom: Date | null;
  dateTo: Date | null;
};

const VaccineUtilizationFilterBar: React.FC<{
  applyHandler: (filter: VaccineUtilizationFilterBarType) => void;
  resetHandler: () => void;
  vaccineValue: IdValueType[];
  dateValue: { dateFrom: Date | null; dateTo: Date | null };
}> = ({ applyHandler, resetHandler, vaccineValue, dateValue }) => {
  const { t } = useTranslation();
  const [selectedVaccines, setSelectedVaccines] = useState<IdValueType[]>([]);
  const [date, setDate] = useState<{
    dateFrom: Date | null;
    dateTo: Date | null;
  }>({ dateFrom: null, dateTo: null });
  const [applyIsClicked, setApplyIsClicked] = useState(false);
  const { vaccines } = useContext(VaccineUtilizationContext);

  useEffect(() => {
    setDate(dateValue);
  }, [dateValue]);

  useEffect(() => {
    setSelectedVaccines(vaccineValue);
  }, [vaccineValue]);

  return (
    <div className="w-95/100 mx-auto flex justify-between flex-wrap">
      <div className="flex flex-col md:flex-row">
        <div>
          <label className="text-sm text-black41">{t("vaccine")}</label>
          <DropdownWithCheckbox
            type={DropdownType.PRIMARY}
            extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
            onOptionSelected={(option) => {
              setSelectedVaccines(option);
            }}
            keys={[
              { id: "", name: "", type: [] },
              ...vaccines.map((vaccine) => vaccine),
            ]}
            selectedKey={selectedVaccines}
            getValueByKey={(key) => {
              if (key === "") return t("all");
              return (
                vaccines.find((vaccine) => vaccine.name === key)?.name || ""
              );
            }}
          />
        </div>
        <div className="md:mx-6 mx-0 md:mt-0 mt-3">
          <label className="text-sm text-black41">{t("interval_date")}</label>
          <div className="flex" dir={i18next.language === "ar" ? "rtl" : "ltr"}>
            <DatePicker
              inputClassName="w-[128px] placeholder:text-gray-d7"
              containerClassName="w-[128px]"
              selected={date.dateFrom}
              placeholderText={t("from")}
              onChange={(value) => {
                setDate({ ...date, dateFrom: value });
              }}
              maxDate={date.dateTo}
            />

            <DatePicker
              inputClassName="w-[128px] placeholder:text-gray-d7"
              containerClassName="w-[128px] mx-3"
              selected={date.dateTo}
              placeholderText={t("to")}
              onChange={(value) => {
                setDate({ ...date, dateTo: value });
              }}
              minDate={date.dateFrom}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse">
        <div>
          {applyIsClicked &&
            (selectedVaccines.length !== 0 ||
              date.dateFrom !== null ||
              date.dateTo !== null) && (
              <button
                onClick={() => {
                  resetHandler();
                  setSelectedVaccines([]);
                  setDate({ dateFrom: null, dateTo: null });
                  setApplyIsClicked(false);
                }}
                className="text-blue-primary hover:font-semibold text-sm mx-6"
              >
                {t("reset")}
              </button>
            )}

          <Button
            className="mt-6"
            type={ButtonTypes.PRIMARY}
            onClick={() => {
              setApplyIsClicked(true);
              applyHandler({
                vaccine: selectedVaccines,
                dateFrom: date.dateFrom,
                dateTo: date.dateTo,
              });
            }}
          >
            {t("apply")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VaccineUtilizationFilterBar;
