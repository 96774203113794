import React, { useContext, useRef, useState } from "react";
import StatusBadge from "../../../UI/StatusBadge";
import { StatusBadgeTypes } from "../../../../utils/Constants";
import Moment from "react-moment";
import { Row } from "react-table";
import LastConfirmWarningModal from "../../../UI/LastConfirmWarningModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faEye, faTrash } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import MenuItem from "../../../UI/MenuItem";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TrainingCandidate from "../../../../types/TrainingCandidate";
import i18next from "i18next";
import { SelectedTrainingCandidatesContext } from "../../../../store/SelectedTrainingCandidatesContext";

const TrainingCandidatesRow: React.FC<{
  row: Row<TrainingCandidate>;
  deleteHandler: (id: number) => void;
}> = ({ row, deleteHandler }) => {
  const { first_name, last_name, email, phone, clinic, supervisor, referral, contact_date, status, id } = row.original;
  const { t } = useTranslation();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const parentContainerRef = useRef<HTMLDivElement>(null);
  const childContainerRef = useRef<HTMLDivElement>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    selectedTrainingCandidates,
    selectTrainingCandidates,
    deselectTrainingCandidates,
  } = useContext(SelectedTrainingCandidatesContext);

  const checked = selectedTrainingCandidates.indexOf(id) !== -1;

  return (
    <>
      <tr className="border-t border-gray-df">
        {/* <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141] sticky left-0">
          <div className="flex">
            <div> {full_name}</div>
          </div>
        </td> */}
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141] sticky left-0">
          <div className="flex items-center">
            <div
              className={`table__checkbox grid place-items-center ${
                i18next.language === "ar" ? "ml-4" : "mr-4"
              } ${checked && "border-blue-primary bg-blue-primary"}`}
              onClick={() => {
                if (checked) {
                  deselectTrainingCandidates(id);
                } else {
                  selectTrainingCandidates(id);
                }
              }}
            >
              {checked && (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-sm text-white"
                />
              )}
            </div>
            {first_name + " " + last_name}
          </div>
        </td>

        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {email}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {phone}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {clinic}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {supervisor}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141] max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap pr-4">
          {referral}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          <div>
            <Moment
              date={contact_date}
              format="DD/MM/YYYY"
              locale={i18next.language === "ar" ? "ar" : "en"}
            />
          </div>
        </td>
        <td className=" td-firefox border-gray-df table__cell text-sm flex justify-between items-center">
          {(() => {
            switch (status) {
              case "RS":
                return (
                  <div>
                    <StatusBadge type={StatusBadgeTypes.RESPONDED} />
                  </div>
                );
              case "PD":
                return (
                  <div>
                    <StatusBadge type={StatusBadgeTypes.PENDING} />
                  </div>
                );
            }
          })()}

          <div className="ml-4" tabIndex={0} onBlur={() => setShowMenu(false)}>
            <div
              ref={parentContainerRef}
              className="py-1 px-3 rounded cursor-pointer hover:bg-gray-f5 relative"
              onClick={() => {
                setShowMenu((preShowMenu) => !preShowMenu);
                const child = childContainerRef.current!;
                const parent = parentContainerRef.current!;
                const scrollableContainer = document.getElementById(
                  "training_candidates-table__container"
                )!;

                child.style.top = `${parent.offsetTop + 32}px`;
                child.style.left = `${
                  parent.offsetLeft - scrollableContainer.scrollLeft - 176 + 28
                }px`;
              }}
            >
              <FontAwesomeIcon
                icon={faEllipsisV}
                className="text-blue-primary text-base"
              />
            </div>
            <div
              ref={childContainerRef}
              className={`absolute z-10 ${showMenu ? "block" : "hidden"}`}
            >
              <div className="rounded shadow bg-white py-1 w-44">
                <MenuItem
                  titleKey={t("preview")}
                  onClick={() => {
                    setShowMenu(false);
                    history.push(`/training-candidate/${id}`);
                  }}
                >
                  <FontAwesomeIcon icon={faEye} className="mx-2" />
                </MenuItem>

                <MenuItem
                  tooltipMessage={t("delete_tooltip")}
                  titleKey={t("delete")}
                  onClick={() => {
                    setShowDeleteModal(true);
                    setShowMenu(false);
                  }}
                >
                  <FontAwesomeIcon className="mx-2" icon={faTrash} />
                </MenuItem>
              </div>
            </div>
          </div>

          {showDeleteModal && (
            <LastConfirmWarningModal
              onBackdrop={() => setShowDeleteModal(false)}
              onCancel={() => setShowDeleteModal(false)}
              onConfirm={() => {
                deleteHandler(id);
                setShowDeleteModal(false);
              }}
              titleKey={"delete"}
              messageKey={"delete_record_message"}
              confirmButtonLabelKey={"yes_delete"}
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default TrainingCandidatesRow;
