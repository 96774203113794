import { Snackbar, SnackbarContent } from "@mui/material";
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import TrainingCandidatesApi from "../../../store/api/trainingCandidatesApi";
import { TrainingCandidatesContext } from "../../../store/TrainingCandidatesContext";
import { getDate } from "../../../utils/getDate";
import { SnackBarType } from "../../../utils/Constants";
import i18next from "i18next";
import LoadingSpinner from "../../UI/LoadingSpinner";
import saveAs from "file-saver";
import { SelectedTrainingCandidatesContext } from "../../../store/SelectedTrainingCandidatesContext";
import { getValueFromList } from "../../../utils/getValue";

export const trainingCandidatesLoader = (Component: any) => {
  return (props: any) => {
    const { t } = useTranslation();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [notFoundMessage, setNotFoundMessage] = useState("");
    const {
      getTrainingCandidates,
      deleteTrainingCandidate,
      exportTrainingCandidates,
    } = TrainingCandidatesApi();
    const {
      loading,
      setLoading,
      setResponseData,
      responseData,
      setReferralList,
      setOrganizationList,
      searchInput,
      filterState,
    } = useContext(TrainingCandidatesContext);
    const [snackBarType, setSnackBarType] = useState<SnackBarType>();
    const { selectedTrainingCandidates, clearSelectedTrainingCandidates } =
      useContext(SelectedTrainingCandidatesContext);

    function constructQueryParams() {
      let queryParams = [];
      if (searchInput) {
        queryParams.push(`search_trainee=${encodeURIComponent(searchInput)}`);
      }
      if (filterState.referral !== "AL") {
        queryParams.push(
          `trainee_referral=${encodeURIComponent(filterState.referral)}`
        );
      }
      if (filterState.status !== "AL") {
        queryParams.push(
          `trainee_status=${encodeURIComponent(filterState.status)}`
        );
      }
      if (filterState.dateFrom !== null) {
        queryParams.push(
          `trainee_from=${encodeURIComponent(
            getDate(filterState.dateFrom.toString(), false)
          )}`
        );
      }
      if (filterState.dateTo !== null) {
        queryParams.push(
          `trainee_to=${encodeURIComponent(
            getDate(filterState.dateTo.toString(), false)
          )}`
        );
      }
      return `?${queryParams.join("&")}`;
    }

    function constructApiUrl() {
      const baseUrl = "training-candidate/";
      return `${baseUrl}${constructQueryParams()}`;
    }

    const getTrainingCandidatesAPI = () => {
      getTrainingCandidates(
        processGetTrainingCandidatesResponse,
        constructApiUrl()
      );
    };

    const processGetTrainingCandidatesResponse = async (response: Response) => {
      const result = await response.json();

      if (!response.ok) {
        setNotFoundMessage(result.clinic);
        return;
      }

      const processedTrainees = result.trainees.map((trainee: any) => ({
        ...trainee,
        referral: getValueFromList(trainee.referral, [...result.referrals, ...result.socials]),
        clinic: getValueFromList(trainee.clinic, result.organizations),
      }));

      setResponseData(processedTrainees);
      setReferralList([...result.referrals, ...result.socials]);
      setOrganizationList(result.organizations);
    };

    const deleteHandler = (_id: number) => {
      deleteTrainingCandidate(
        (response) => async (response: Response, _id: number) => {
          if (response.ok) {
            setSnackBarType(SnackBarType.DELETE);
            setShowSnackbar(true);
            const newData = [...responseData];
            const index = newData.findIndex(
              (trainingCandidate) => trainingCandidate.id === _id
            );
            newData.splice(index, 1);
            setResponseData(newData);
          } else {
            console.log("message: training candidate not found");
          }
        },
        _id
      );
    };

    const exportHandler = () => {
      setLoading(true);
      exportTrainingCandidates(
        constructQueryParams() +
        (selectedTrainingCandidates.length > 0
          ? `&selected_items=${selectedTrainingCandidates
            .map((selectedItem) => selectedItem)
            .join(",")}`
          : ""),
        async (response) => {
          if (response.ok) {
            setTimeout(async () => {
              try {
                const blob = await response.blob();
                saveAs(blob, ` "training_candidates".csv`);
                setLoading(false);
                clearSelectedTrainingCandidates();
                setSnackBarType(SnackBarType.DOWNLOAD);
                setShowSnackbar(true);
              } catch (e) {
                console.log("error:file not found");
              } finally {
                setLoading(false);
              }
            }, 1000);
          }
        }
      );
    };

    return (
      <>
        <Snackbar
          dir={i18next.language === "ar" ? "rtl" : "ltr"}
          open={showSnackbar}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "#03A678",
            },
            "& .MuiSnackbarContent-message": {
              fontSize: "16px",
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: "100",
            },
          }}
          onClose={() => setShowSnackbar(false)}
        >
          <SnackbarContent
            message={
              <p>
                {snackBarType === SnackBarType.DELETE &&
                  t("record_deleted_successfully")}
                {snackBarType === SnackBarType.DOWNLOAD && (
                  <>{t("export_completed__message")}</>
                )}
              </p>
            }
          />
        </Snackbar>
        {loading && (
          <LoadingSpinner
            onBackdrop={() => setLoading(false)}
            titleKey={t("exporting_to_csv")}
          />
        )}
        <Component
          {...props}
          notFoundMessage={notFoundMessage}
          getTrainingCandidates={getTrainingCandidatesAPI}
          deleteHandler={deleteHandler}
          exportHandler={exportHandler}
        />
      </>
    );
  };
};
