import useAPI from "../../hooks/useAPI";

const SubscribersApi = () => {
  const { sendRequest } = useAPI();

  const getSubscriber = (subscriberPreccessResponse: (response: Response) => void, url: string) => {
    sendRequest(
      {
        url: url,
      },
      subscriberPreccessResponse
    );
  };

  const exportSubscribers = (slugs: { slug: string }[], exportPreccessResponse: (response: Response) => void, url: string) => {
    sendRequest(
      {
        url: `export-${url}`,
        method: "POST",
        body: JSON.stringify(slugs),
      },
      exportPreccessResponse
    );
  };

  const updateStatus = (subscribersPreccessResponse: (response: Response) => void, _id: string) => {
    sendRequest(
      {
        url: `subscribers/${_id}/`,
        method: "PATCH",
      },
      subscribersPreccessResponse
    );
  };
  return { getSubscriber, exportSubscribers, updateStatus };
};

export default SubscribersApi;
