import React, { useState, useEffect } from "react";
import ChildrenAPI from "./api/childrenAPI";
import i18next from "i18next";
import IdValueType from "../types/IdValueType";

type ChildrenFilter = {
  vaccines: IdValueType[];
  vaccineStatuses: VaccineStatus[];
  injectionStatuses: VaccineStatus[];
};

export type VaccineStatus = {
  key: string;
  name: string;
  icon: string;
  color: string;
};

const DEFAULT_CHILDREN_FILTER = {
  vaccines: [],
  vaccineStatuses: [],
  injectionStatuses: [],
};

type SearchFilterChildrenContextType = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  childrenFilter: ChildrenFilter;
  childrenAppliedFilter: ChildrenFilter;
  setChildrenFilter: React.Dispatch<React.SetStateAction<ChildrenFilter>>;
  setChildrenAppliedFilter: React.Dispatch<React.SetStateAction<ChildrenFilter>>;
  vaccines: IdValueType[];
  vaccineStatuses: VaccineStatus[];
  injectionStatuses: VaccineStatus[];
  reset: () => void;
  isAnyFilterApplied: () => boolean;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
};

export const SearchFilterChildrenContext = React.createContext<SearchFilterChildrenContextType>({
  searchValue: '',
  setSearchValue: () => {},
  childrenFilter: DEFAULT_CHILDREN_FILTER,
  childrenAppliedFilter: DEFAULT_CHILDREN_FILTER,
  vaccines: [],
  vaccineStatuses: [],
  injectionStatuses: [],
  setChildrenFilter: () => {
    //
  },
  setChildrenAppliedFilter: () => {
    ///
  },
  reset: () => {
    //
  },
  isAnyFilterApplied: () => false,
  setLanguage: () => {
    //
  },
});

const SearchFilterChildrenContextProvider: React.FC = ({ children }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [childrenFilter, setChildrenFilter] = useState<ChildrenFilter>(DEFAULT_CHILDREN_FILTER);
  const [childrenAppliedFilter, setChildrenAppliedFilter] = useState<ChildrenFilter>(DEFAULT_CHILDREN_FILTER);
  const [vaccines, setVaccines] = useState<IdValueType[]>([]);
  const [vaccineStatuses, setVaccineStatuses] = useState<VaccineStatus[]>([]);
  const [injectionStatuses, setInjectionStatuses] = useState<VaccineStatus[]>([]);
  const { getChildren } = ChildrenAPI();
  const [language, setLanguage] = useState<string>(i18next.language);

  const reset = () => {
    setChildrenAppliedFilter(DEFAULT_CHILDREN_FILTER);
  };

  useEffect(() => {
    if (language) {
      getChildren(processGetChildrenResponse, "children-dropdown/");
    }
  }, [language]);

  const processGetChildrenResponse = async (response: Response) => {
    const result = await response.json();
    if (response.ok) {
      setVaccines(result.vaccination);
      setVaccineStatuses(result.vaccination_type);
      setInjectionStatuses(result.child_vaccination_status);
    }
  };

  const isAnyFilterApplied = () => {
    return (
      childrenAppliedFilter.vaccines.length !== DEFAULT_CHILDREN_FILTER.vaccines.length ||
      childrenAppliedFilter.vaccineStatuses.length !== DEFAULT_CHILDREN_FILTER.vaccineStatuses.length ||
      childrenAppliedFilter.injectionStatuses.length !== DEFAULT_CHILDREN_FILTER.injectionStatuses.length
    );
  };

  return (
    <SearchFilterChildrenContext.Provider
      value={{
        searchValue,
        setSearchValue,
        childrenFilter,
        childrenAppliedFilter,
        setChildrenFilter,
        setChildrenAppliedFilter,
        vaccines,
        vaccineStatuses,
        injectionStatuses,
        reset,
        isAnyFilterApplied,
        setLanguage,
      }}
    >
      {children}
    </SearchFilterChildrenContext.Provider>
  );
};

export default SearchFilterChildrenContextProvider;
